import './Project.css'

import add from './images/add.svg'

export default function Project (props) {
        return (
                <div className="Project" onClick={()=>props.clickHandler(props.name ? props.name : "4565_CreatenewNew2092")}>
                        <div className="Project_icon_container">
                                <img className="Project_icon" src={props.logo ? props.logo : add} alt="Add"/>
                        </div>
                        <div className="Project_label">
                                <div className='Project_name'>{props.name ? props.name : "Start New"}</div>
                                <div className='Project_teamSize'>Team size: {props?.teamSize}</div>
                        </div>
                </div>
        )
}