import './Tool.css'

import { useEffect, useRef, useState } from 'react'
import CustomMarkdown from '../CustomMarkdown/CustomMarkdown'
import { Document, HeadingLevel, Packer, Paragraph } from 'docx'
import { saveAs } from "file-saver"

import Base from '../../components/Base/Base'
import TextLoader from '../../components/TextLoader/TextLoader'
import Footer from '../Footer/Footer'

import download from "../../assets/images/download.svg"
import edit from "../../assets/images/edit.svg"
import tick from "../../assets/images/tick.svg"
import Button from '../../components/Button/Button'

import advantage1 from "../../assets/images/bre_advantage1.svg"
import advantage2 from "../../assets/images/bre_advantage2.svg"
import advantage3 from "../../assets/images/bre_advantage3.svg"
import advantage4 from "../../assets/images/bre_advantage4.svg"
import advantage5 from "../../assets/images/bre_advantage5.svg"

import { backendUri } from '../../constants'

export default function Tool (props) {
        const [loading, setLoading] = useState(false)

        const [projects, setProjects] = useState([]);
        const retrieveProjects = () => {
                var myHeaders = new Headers();
                myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

                var formdata = new FormData();
                formdata.append("isMembers", 1);

                const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        // body: formdata,
                        redirect: "follow",
                };

                fetch(`${backendUri}/api/project/get`, requestOptions)
                        .then((response) => response.text())
                        .then((result) => setProjects(JSON.parse(result).data.projects))
                        .catch((error) => console.log(error));
        };
        useEffect(() => retrieveProjects(), []);

        const [parameters, setParameters] = useState([])

        const [apiResponse, setApiResponse] = useState("")
        const handleGenerateClick = isUpdate => {
                setLoading(true)

                var myHeaders = new Headers()
                myHeaders.append("profileObj", sessionStorage.getItem("profileObj"))

                var formdata = new FormData()

                console.log(typeof props?.primaryInput?.current?.value === "string")

                typeof props?.primaryInput?.current?.value !== "string" ?
                        [...props?.primaryInput].map(file => formdata.append("file", file))
                        :
                        formdata.append("userInput", props?.primaryInput?.current?.value)

                console.log("Tool", props?.secondaryInputs)

                if(props?.secondaryInputs)
                        props?.secondaryInputs.map(input => formdata.append(input[0], input[1]))

                if (props?.name === "UIDEV" || props?.name === "IMGJSON")
                        formdata.append("chatgpt", parameters?.LLM ?? "GPTVISION")

                if (!isUpdate && props?.name !== "MANUALTIME")
                        formdata.append("generate", "GEN")

                if (parameters?.context)
                        formdata.append("context", parameters?.context)

                formdata.append("temp", parameters?.temperature)
                formdata.append("phase", props?.name)
                if (sessionStorage.getItem("role") !== "1")
                        formdata.append("ProjectName", projects[0].ProjectName);

                fetch(`${backendUri}/api/upload`, {
                        method: "POST",
                        headers: myHeaders,
                        body: formdata,
                        redirect: "follow",
                })
                .then((response) => response.text())
                .then((result) => {
                        setLoading(false)
                        setApiResponse(JSON.parse(result).data.response)
                })
                .catch((error) => {
                        setLoading(false)
                        console.log(error)
                });
        }

        const [outputEdit, setOutputEdit] = useState(false);
        const handleEditClick = () => {
                setOutputEdit(p => p === "Save" ? "Edit" : "Save")
        }

        const handleDownloadClick = () => {
                if (apiResponse) {
                        const lines = apiResponse.replaceAll("**", "").split("\n");
                        const docx = new Document({
                                sections: [{
                                        children: lines.map(line =>
                                                line.startsWith("# ") ? new Paragraph({text: line.slice(2), heading: HeadingLevel.HEADING_1})
                                                : line.startsWith("## ") ? new Paragraph({text: line.slice(3), heading: HeadingLevel.HEADING_2})
                                                : line.startsWith("### ") ? new Paragraph({text: line.slice(4), heading: HeadingLevel.HEADING_3})
                                                : line.startsWith("#### ") ? new Paragraph({text: line.slice(5), heading: HeadingLevel.HEADING_4})
                                                : line.startsWith("##### ") ? new Paragraph({text: line.slice(6), heading: HeadingLevel.HEADING_5})
                                                : line.startsWith("###### ") ? new Paragraph({text: line.slice(7), heading: HeadingLevel.HEADING_6})
                                                : line.startsWith("- ") ? new Paragraph({ text: line.slice(2), bullet: { level: 0 } })
                                                : new Paragraph({ text: line })
                                        )
                                }]
                        })

                        Packer.toBlob(docx).then(blob => saveAs(blob, `${props?.label}.docx`))
                }
        };

        return (
                <Base label={props?.label} icon={props?.icon} separate={props?.separate ? true : false}>
                        <TextLoader appName={props?.separate ? "KaiBRE" : "KaiSDLC"} loading={loading} loaderMessage={`Generating ${props?.label} document`}/>

                        <div className='Tool_padding'>
                                <div className='Tool_container'>
                                        <div className='Tool_partition'>
                                                <div className='Tool_inputs'>
                                                        {props?.children}
                                                </div>

                                                <Footer
                                                        type="input"
                                                        parameterKeys={props?.parameters}
                                                        parameterValues={setParameters}
                                                        handleUploadClick={props?.setFiles}
                                                        handleGenerateClick={()=>handleGenerateClick(false)}
                                                        handleUpdateClick={()=>handleGenerateClick(true)}
                                                />
                                        </div>

                                        <div className='Tool_partition'>
                                                {props?.separate && !apiResponse ?
                                                        <div className="SN_advantagesContainer">
                                                                <div className='SN_advantages_header'>
                                                                        Kai<span className='SN_advantages_para_bre'>BRE</span> Advantages
                                                                </div>
                                                                <div className="SN_advantages_para"><span className="SN_advantages_para_bre">Business Rule Extraction</span> is deriving the underlying business methods through analysis of code. Here are the key features of KaiBRE:</div>
                                                                <div className="SN_advantagesList">
                                                                        <div className="SN_advantage">
                                                                                <img src={advantage1} alt="Advantage"/>
                                                                                <div className="SN_advantageContent">Understand apps better through business rules</div>
                                                                        </div>
                                                                        <div className="SN_advantage">
                                                                                <img src={advantage2} alt="Advantage"/>
                                                                                <div className="SN_advantageContent">Improve understanding of legacy applications</div>
                                                                        </div>
                                                                        <div className="SN_advantage">
                                                                                <img src={advantage3} alt="Advantage"/>
                                                                                <div className="SN_advantageContent">Reduce human errors</div>
                                                                        </div>
                                                                        <div className="SN_advantage">
                                                                                <img src={advantage4} alt="Advantage"/>
                                                                                <div className="SN_advantageContent">Automate modernization efforts</div>
                                                                        </div>
                                                                        <div className="SN_advantage">
                                                                                <img src={advantage5} alt="Advantage"/>
                                                                                <div className="SN_advantageContent">Multi-language support</div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        :
                                                        <div className="Tool_outputContainer">
                                                                <div className='Tool_output_floatingHeader'>
                                                                        <Button icon={outputEdit ? tick : edit} collapsible onClick={handleEditClick}>{outputEdit ? "Save" : "Edit"}</Button>
                                                                        <Button icon={download} collapsible onClick={handleDownloadClick}>Download</Button>
                                                                </div>
                                                                <div className='Tool_outputContent'>
                                                                        <CustomMarkdown>{apiResponse}</CustomMarkdown>
                                                                </div>
                                                        </div>
                                                }
                                        </div>
                                </div>
                        </div>
                </Base>
        )
}
