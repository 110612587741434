import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import tick from "../../assets/images/tick.svg"
import copy from "../../assets/images/copy.svg"

export default function CustomMarkdown (props)
{
        const codeHeader = {
                height: "30px",
                padding: "1px 5px 0px 10px",
                backgroundColor: "#7a6652",
                borderTopLeftRadius: "0.5em",
                borderTopRightRadius: "0.5em",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
        }

        const languageLabel = {
                color: "#f3f3f3"
        }

        const copyButtonStyle = {
                height: "100%",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                gap: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                paddingTop: "2px"
        }

        const copyIconStyle = {
                width: "12px",
                filter: "invert(100%) sepia(2%) saturate(154%) hue-rotate(175deg) brightness(113%) contrast(91%)"
        }

        const copyLabelStyle = {
                color: "#f3f3f3",
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                cursor: "pointer"
        }

        const codeContainer = {
                fontSize: "12px",
                margin: "0 0 0",
                borderTop: "none",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0"
        }

        const [copyButtonText, setCopyButtonText] = useState("Copy")
        const copyText = (children) =>
        {
                navigator.clipboard.writeText(children)
                setCopyButtonText(p => p === "Copy" ? "Copied!" : "Copy")
                setTimeout(() => {
                        setCopyButtonText(p => p === "Copy" ? "Copied!" : "Copy")
                }, 3000);
        }

        return (
                <ReactMarkdown
                        children={props?.children}
                        components={{
                                code(props) {
                                        const {children, className, node, ...rest} = props
                                        const match = /language-(\w+)/.exec(className || '')
                                        return match ?
                                                <>
                                                        <div style={codeHeader}>
                                                                <div style={languageLabel}>{match[1]}</div>

                                                                <button style={copyButtonStyle} onClick={()=>copyText(children)}>
                                                                        <img style={copyIconStyle} src={copyButtonText === "Copy" ? copy : tick} alt="Copy"/>
                                                                        <label style={copyLabelStyle}>{copyButtonText}</label>
                                                                </button>
                                                        </div>
                                                        <SyntaxHighlighter
                                                                {...rest}
                                                                PreTag="div"
                                                                children={String(children).replace(/\n$/, '')}
                                                                language={match[1]}
                                                                style={dark}
                                                                customStyle={codeContainer}
                                                        />
                                                </>
                                                :
                                                <code {...rest} className={className}>{children}</code>
                                }
                        }}
                />
        )
}
