import './index.css'

import ReactDOM from 'react-dom/client';
import App from './App';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './msauth';

import { BrowserRouter } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css'

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <MsalProvider instance={msalInstance}>
                <BrowserRouter>
                        <App />
                </BrowserRouter>
        </MsalProvider>
);
