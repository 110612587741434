import './Login.css'

import { useEffect, useState } from 'react'

import app_logo from '../../assets/images/Datamatics_KaiSDLC_stacked.svg'
import bre_app_logo from "../../assets/images/Datamatics_KaiBRE_stacked.svg"
import TruTest_logo from "../../assets/images/Datamatics_KaiTest_stacked.svg"

import GoogleLoginButton from './components/google/GoogleLoginButton'
import MSLoginButton from './components/ms/MSLoginButton'

export default function Login (props) {
        const [errorText, setErrorText] = useState("")

        useEffect(() => sessionStorage.removeItem("phase"), [])

        const iconsList = [
                ["TruSDLC", app_logo, "/sdlc"],
                ["TruTest", TruTest_logo, "/trutest"],
                ["TruBRE", bre_app_logo, "/kaibre"]
        ]

        return (
                <div className="Login_container">
                        <div className='Login_imageBox'/>

                        <div className="Login_contentBox">
                                <div className="Login_imageRow">
                                        <img className="Login_appLogo" src={iconsList.filter(iconItem => iconItem[0] === props?.name)[0][1]} alt="KaiSDLC"/>
                                </div>

                                <div className="Login_buttonsRow">
                                        <div className="Login_buttonsRow_buttons">
                                                <GoogleLoginButton getProfileObj={props.getProfileObj} setErrorText={setErrorText} url={iconsList.filter(iconItem => iconItem[0] === props?.name)[0][2]}/>
                                                <MSLoginButton setErrorText={setErrorText} url={iconsList.filter(iconItem => iconItem[0] === props?.name)[0][2]}/>
                                        </div>

                                        <div className="Login_error">{errorText}</div>
                                </div>
                        </div>
                </div>
        )
}
