import "./Estimates.css";

import { useRef, useState } from "react";

import Tool from "../../components/Tool/Tool";

import estimates from "../../assets/images/tool_time.svg";

export default function Estimates() {
  const input = useRef();
  const [files, setFiles] = useState(null);
  const frontend = useRef();
  const backend = useRef();

  return (
    <Tool
      name="MANUALTIME"
      label="Estimates"
      icon={estimates}
      parameters={["RAG", "LLM", "Context", "Temperature"]}
      primaryInput={files ?? input}
      setFiles={setFiles}
      secondaryInputs={[
        ["frontend", frontend],
        ["backend", backend],
      ]}
    >
      <div className="Estimates_textareaContainer">
        {files ? (
          <div className="Estimates_input file">
            {[...files].map((file, i) => (
              <div className="Estimates_file" key={i}>
                {file.name}
              </div>
            ))}
          </div>
        ) : (
          <textarea
            className="Estimates_input"
            placeholder="Enter/upload your initial requirements"
            ref={input}
          />
        )}
      </div>

      <div className="Estimates_techstackInputs">
        <div className="Estimates_dropdownContainer">
          <input
            type="text"
            className="Estimates_dropdown"
            placeholder="Enter Frontend"
            ref={frontend}
          />
        </div>

        <div className="Estimates_dropdownContainer">
          <input
            type="text"
            className="Estimates_dropdown"
            placeholder="Enter Backend"
            ref={backend}
          />
        </div>
      </div>
    </Tool>
  );
}
