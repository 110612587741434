import "./CodeConversion.css";
import { useRef, useState } from "react";
import Tool from "../../components/Tool/Tool";
import codeconversion from "../../assets/images/tool_codeconversion.svg";

export default function CodeConversion () {
        const input = useRef("")
        const [files, setFiles] = useState(null)
        const [fromLanguage, setFromLanguage] = useState("")
        const [toLanguage, setToLanguage] = useState("")

        console.log(fromLanguage, toLanguage)

        return (
                <Tool
                        name="CODCON"
                        label="Code Conversion"
                        icon={codeconversion}
                        parameters={["RAG", "LLM", "Context", "Temperature"]}
                        primaryInput={files ?? input} setFiles={setFiles}
                        secondaryInputs={[["context", "Convert this code from " + fromLanguage + " to " + toLanguage]]}
                >
                        <div className='Estimates_textareaContainer'>
                                {files ?
                                        <div className='Estimates_input file'>
                                                {[...files].map((file, i) => <div className='Estimates_file' key={i}>{file.name}</div>)}
                                        </div>
                                        :
                                        <textarea className='Estimates_input' placeholder='Enter/upload your code' ref={input}/>
                                }
                        </div>

                        <div className='Estimates_techstackInputs'>
                                <div className='Estimates_dropdownContainer'>
                                        <input
                                                type="text"
                                                className="Estimates_input"
                                                placeholder="Convert from:"
                                                value={fromLanguage}
                                                onChange={(e) => setFromLanguage(e.target.value)}
                                        />
                                </div>

                                <div className='Estimates_dropdownContainer'>
                                        <input
                                                type="text"
                                                className="Estimates_input"
                                                placeholder="Convert to:"
                                                value={toLanguage}
                                                onChange={(e) => setToLanguage(e.target.value)}
                                        />
                                </div>
                        </div>
                </Tool>
        )
}
