// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MSLogin_container {
        /* opacity: 0.1; */

        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #000;
        border: 1px solid #e4e4e4;
        border-radius: 4px;

        height: 40px;
        width: 250px;
        padding-left: 20px;

        cursor: pointer;
}

.MSLogin_iconContainer {
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
}

.MSLogin_icon {
        height: 15px;
}

.MSLogin_label {
        color: #fff;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Login/components/ms/MSLoginButton.css"],"names":[],"mappings":"AAAA;QACQ,kBAAkB;;QAElB,aAAa;QACb,mBAAmB;QACnB,SAAS;QACT,sBAAsB;QACtB,yBAAyB;QACzB,kBAAkB;;QAElB,YAAY;QACZ,YAAY;QACZ,kBAAkB;;QAElB,eAAe;AACvB;;AAEA;QACQ,YAAY;QACZ,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,uBAAuB;AAC/B;;AAEA;QACQ,YAAY;AACpB;;AAEA;QACQ,WAAW;QACX,yBAAyB;QACzB,eAAe;QACf,gBAAgB;AACxB","sourcesContent":[".MSLogin_container {\n        /* opacity: 0.1; */\n\n        display: flex;\n        align-items: center;\n        gap: 10px;\n        background-color: #000;\n        border: 1px solid #e4e4e4;\n        border-radius: 4px;\n\n        height: 40px;\n        width: 250px;\n        padding-left: 20px;\n\n        cursor: pointer;\n}\n\n.MSLogin_iconContainer {\n        height: 100%;\n        width: 40px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n}\n\n.MSLogin_icon {\n        height: 15px;\n}\n\n.MSLogin_label {\n        color: #fff;\n        font-family: \"Montserrat\";\n        font-size: 12px;\n        font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
