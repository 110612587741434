import { useRef, useState } from "react";

import Tool from "../../components/Tool/Tool";

import defectRCA from "../../assets/images/tool_defectRca.svg";

export default function DefectRCA () {
        const input = useRef("")
        const [files, setFiles] = useState(null)

        return (
                <Tool
                        name="RCA"
                        label="Defect RCA"
                        icon={defectRCA}
                        parameters={["RAG", "LLM", "Context", "Temperature"]}
                        primaryInput={files ?? input} setFiles={setFiles}
                >
                        <div className='Estimates_textareaContainer'>
                                {files ?
                                        <div className='Estimates_input file'>
                                                {[...files].map((file, i) => <div className='Estimates_file' key={i}>{file.name}</div>)}
                                        </div>
                                        :
                                        <textarea className='Estimates_input' placeholder='Upload your bug report document' ref={input} disabled/>
                                }
                        </div>
                </Tool>
        )
}
