// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Phase_container {
  height: 140px;
  width: 120px;

  margin-right: 30px;
  margin-bottom: 30px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  border: 2px solid transparent;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.Phase_container:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.Phase_image {
  height: 60px;
  width: auto;
  margin-bottom: 12px;
  filter: grayscale(50%);
  transition: filter 0.3s ease;
}

.Phase_container:hover .Phase_image {
  filter: grayscale(0%);
}

.Phase_title {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 8px;
}

@keyframes borderAnimation {
  20% { border-color: #eeafaf; }
}

.Phase_container:hover {
  animation: borderAnimation 2s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Phases/Phase/Phase.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;;EAEZ,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;;EAEb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;;EAE9B,gBAAgB;;EAEhB,6BAA6B;EAC7B,mBAAmB;EACnB,yBAAyB;EACzB,yCAAyC;;EAEzC,qDAAqD;AACvD;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,MAAM,qBAAqB,EAAE;AAC/B;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".Phase_container {\n  height: 140px;\n  width: 120px;\n\n  margin-right: 30px;\n  margin-bottom: 30px;\n  padding: 16px;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n\n  overflow: hidden;\n\n  border: 2px solid transparent;\n  border-radius: 16px;\n  background-color: #ffffff;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  transition: transform 0.2s ease, box-shadow 0.3s ease;\n}\n\n.Phase_container:hover {\n  transform: translateY(-3px);\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);\n}\n\n.Phase_image {\n  height: 60px;\n  width: auto;\n  margin-bottom: 12px;\n  filter: grayscale(50%);\n  transition: filter 0.3s ease;\n}\n\n.Phase_container:hover .Phase_image {\n  filter: grayscale(0%);\n}\n\n.Phase_title {\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 12px;\n  font-weight: 600;\n  color: #333333;\n  text-align: center;\n  margin-bottom: 8px;\n}\n\n@keyframes borderAnimation {\n  20% { border-color: #eeafaf; }\n}\n\n.Phase_container:hover {\n  animation: borderAnimation 2s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
