import './TruBRE.css'

import { useRef, useState } from 'react'

import Tool from '../../components/Tool/Tool'

import TruBRE_logo from "../../assets/images/Datamatics_KaiBRE_stacked.svg"

export default function TruBRE (props) {
        const input = useRef()
        const [files, setFiles] = useState(null)
        const language = useRef()

        return (
                <Tool
                        name="CODD"
                        label="KaiBRE"
                        icon={TruBRE_logo}
                        parameters={["RAG", "LLM", "Context", "Temperature"]}
                        primaryInput={files ?? input} setFiles={setFiles}
                        secondaryInputs={[["context", language]]}
                        separate={props?.separate}
                >
                        <div className='Estimates_textareaContainer'>
                                {files ?
                                        <div className='Estimates_input file'>
                                                {[...files].map((file, i) => <div className='Estimates_file' key={i}>{file.name}</div>)}
                                        </div>
                                        :
                                        <textarea className='Estimates_input' placeholder='Enter/upload your code' ref={input}/>
                                }
                        </div>

                        <div className='Estimates_techstackInputs'>
                                <div className='Estimates_dropdownContainer'>
                                        <select className='Estimates_dropdown' ref={language}>
                                                <option>Select Language</option>
                                                <option>VB</option>
                                                <option>COBOL</option>
                                                <option>C</option>
                                                <option>Pascal</option>
                                                <option>Perl</option>
                                                <option>Fortran</option>
                                        </select>
                                </div>
                        </div>
                </Tool>
        )
}
