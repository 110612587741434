// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button {
        height: 30px;

        padding: 0 15px;

        border: none;
        border-radius: 4px;

        cursor: pointer;

        display: flex;
        align-items: center;
        flex-shrink: 0;

        gap: 10px;

        font-family: "Montserrat";

        transition: transform ease 0.3s;
}

.Button:hover {
        transform: translateY(-2px)
}

.Button.collapsible {
        padding: 0 10px;

        max-width: 35px;
        overflow: hidden;

        transform: none;
        transition: max-width ease-in 0.5s, ease-out 0.7s;
}

.Button.collapsible:hover {
        max-width: 150px;
}

.Button:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

.Button:disabled {
        opacity: 0.4;
}

.Button_label {
        font-size: 11px;
        font-weight: 600;
}

.Button_icon {
        height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;QACQ,YAAY;;QAEZ,eAAe;;QAEf,YAAY;QACZ,kBAAkB;;QAElB,eAAe;;QAEf,aAAa;QACb,mBAAmB;QACnB,cAAc;;QAEd,SAAS;;QAET,yBAAyB;;QAEzB,+BAA+B;AACvC;;AAEA;QACQ;AACR;;AAEA;QACQ,eAAe;;QAEf,eAAe;QACf,gBAAgB;;QAEhB,eAAe;QACf,iDAAiD;AACzD;;AAEA;QACQ,gBAAgB;AACxB;;AAEA;QACQ,wCAAwC;AAChD;;AAEA;QACQ,YAAY;AACpB;;AAEA;QACQ,eAAe;QACf,gBAAgB;AACxB;;AAEA;QACQ,YAAY;AACpB","sourcesContent":[".Button {\n        height: 30px;\n\n        padding: 0 15px;\n\n        border: none;\n        border-radius: 4px;\n\n        cursor: pointer;\n\n        display: flex;\n        align-items: center;\n        flex-shrink: 0;\n\n        gap: 10px;\n\n        font-family: \"Montserrat\";\n\n        transition: transform ease 0.3s;\n}\n\n.Button:hover {\n        transform: translateY(-2px)\n}\n\n.Button.collapsible {\n        padding: 0 10px;\n\n        max-width: 35px;\n        overflow: hidden;\n\n        transform: none;\n        transition: max-width ease-in 0.5s, ease-out 0.7s;\n}\n\n.Button.collapsible:hover {\n        max-width: 150px;\n}\n\n.Button:focus {\n        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);\n}\n\n.Button:disabled {\n        opacity: 0.4;\n}\n\n.Button_label {\n        font-size: 11px;\n        font-weight: 600;\n}\n\n.Button_icon {\n        height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
