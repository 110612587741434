import './TextLoader.css'

export default function TextLoader (props) {
        const heading = props?.appName ?? "KaiSDLC"
        return props.loading && (
                <div className="loader-container">
                        <div className="loader">
                                <div className="loader-container">
                                        <div className="loader-text">
                                                {heading.split("").map((char, index) => (<span key={index} style={{animationDelay: `${index * 0.1}s`, animationName: "color-change"}}>{char}</span>))}
                                                <span className="cursor"></span>
                                        </div>
                                        <div className="loading-message">
                                                {props.loaderMessage}
                                                <span className="dot">.</span>
                                                <span className="dot">.</span>
                                                <span className="dot">.</span>
                                        </div>
                                </div>
                        </div>
                        <div className="blur-background" />
                </div>
        )
}