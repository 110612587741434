import './Button.css'

export default function Button (props) {
        const buttonStyle = {
                background: props.background ?? "#fff",
                border: props.noBorder ? "none" : "1px solid #e4e4e4"
        }
        const labelStyle = {
                color: props.color ?? "#000",
                cursor: "pointer"
        }
        const iconStyle = {
                filter: `brightness(0) ${props.color ? props.color === "#fff" ? "invert(1)" : "" : ""}`
        }
        return (
                <button className={`Button ${props.collapsible ? "collapsible" : ""}`} onClick={props.onClick} style={buttonStyle}>
                        <img className="Button_icon" src={props.icon} style={iconStyle} alt={props.children}/>
                        <label className="Button_label" style={labelStyle}>{props.children}</label>
                </button>
        )
}
