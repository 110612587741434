import { GoogleLogin } from "react-google-login";

import google from "../../../../assets/images/google.svg";

import { backendUri, clientId } from "../../../../constants";

export default function GoogleLoginButton(props) {
        const onSuccess = (res) => {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                        UserEmailId: res.profileObj.email,
                        UserGoogleId: res.profileObj.googleId,
                        FirstName: res.profileObj.givenName,
                        // LastName: res.profileObj.familyName,
                        Authentication: "Google",
                });

                var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                };

                fetch(`${backendUri}/api/login`, requestOptions)
                .then(response => response.text())
                .then(result => {
                        sessionStorage.setItem("role", JSON.parse(result).data.RoleId)
                        sessionStorage.setItem("profileObj", res.profileObj.googleId)
                        window.location.href = props?.url
                })
                .catch(e => {
                        console.log(e)
                        props.setErrorText("Login failed. Please try again.")
                })
        }
        const onFailure = e => {
                console.log(e)
                props.setErrorText("Login failed. Please try again.")
        }

        const googleLogin = {
                display: "flex",
                alignItems: "center",
                gap: "10px",
                border: "1px solid #e4e4e4",
                borderRadius: "4px",
                paddingLeft: "20px",
                backgroundColor: "#fff",
                height: "40px",
                width: "250px",
                marginBottom: "15px",
                cursor: "pointer",
        };

        const googleLabel = {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "600",
                flexGrow: "1",
        };

        const iconContainer = {
                height: "100%",
                minWidth: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
        };

        const customImage = {
                cursor: "pointer",
                width: "17px",
        };

        return (
                <div id="signInButton">
                        <GoogleLogin
                                clientId={clientId}
                                render={(renderProps) => (
                                        <div onClick={renderProps.onClick} style={googleLogin}>
                                                <div style={iconContainer}>
                                                        <img src={google} style={customImage} alt="Google" />
                                                </div>
                                                <div style={googleLabel}>Sign in with Google</div>
                                        </div>
                                )}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={"single_host_origin"}
                        />
                </div>
        );
}
