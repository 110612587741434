// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MSLogout_container {
        display: flex;
        align-items: center;
        background-color: #000;
        border: 1px solid #e4e4e4;

        height: 40px;
        width: 110px;
        padding-right: 15px;

        cursor: pointer;
}

.MSLogout_iconContainer {
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
}

.MSLogout_icon {
        height: 15px;
}

.MSLogout_label {
        color: #fff;
        font-family: "Montserrat";
        font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Login/components/ms/MSLogoutButton.css"],"names":[],"mappings":"AAAA;QACQ,aAAa;QACb,mBAAmB;QACnB,sBAAsB;QACtB,yBAAyB;;QAEzB,YAAY;QACZ,YAAY;QACZ,mBAAmB;;QAEnB,eAAe;AACvB;;AAEA;QACQ,YAAY;QACZ,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,uBAAuB;AAC/B;;AAEA;QACQ,YAAY;AACpB;;AAEA;QACQ,WAAW;QACX,yBAAyB;QACzB,eAAe;AACvB","sourcesContent":[".MSLogout_container {\n        display: flex;\n        align-items: center;\n        background-color: #000;\n        border: 1px solid #e4e4e4;\n\n        height: 40px;\n        width: 110px;\n        padding-right: 15px;\n\n        cursor: pointer;\n}\n\n.MSLogout_iconContainer {\n        height: 100%;\n        width: 40px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n}\n\n.MSLogout_icon {\n        height: 15px;\n}\n\n.MSLogout_label {\n        color: #fff;\n        font-family: \"Montserrat\";\n        font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
