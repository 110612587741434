import './CodeToBRD.css'

import { useRef, useState } from 'react'

import Tool from '../../components/Tool/Tool'

import codetobrd from "../../assets/images/tool_codetobrd.svg"

export default function CodeToBRD () {
        const input = useRef()
        const [files, setFiles] = useState(null)

        return (
                <Tool
                        name="CODBRD"
                        label="Code To BRD"
                        icon={codetobrd}
                        parameters={["RAG", "LLM", "Context", "Temperature"]}
                        primaryInput={files ?? input} setFiles={setFiles}
                >
                        <div className='Estimates_textareaContainer'>
                                {files ?
                                        <div className='Estimates_input file'>
                                                {[...files].map((file, i) => <div className='Estimates_file' key={i}>{file.name}</div>)}
                                        </div>
                                        :
                                        <textarea className='Estimates_input' placeholder='Enter/upload your code' ref={input}/>
                                }
                        </div>
                </Tool>
        )
}
