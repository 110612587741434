// export const clientId =
//   "1057553169932-8gkedvq4su08s1c1ud8r99umbl9kg7kc.apps.googleusercontent.com";
// export const backendUri = "http://15.206.212.133:5006";
// export const backendUri = "http://localhost:5006";

// newProduction
export const clientId =
  "946797744458-2inb949n06je5divn1lvtelvqbrkbu8h.apps.googleusercontent.com";
export const backendUri = "https://trusdlc-genai.techjini.com/api";
// export const backendUri = "http://ec2-user@ec2-65-2-181-226.ap-south-1.compute.amazonaws.com:5006"

// oldProduction
// export const clientId = "946797744458-7enp6c375u21psq56our7aq1id5f8nrl.apps.googleusercontent.com"
// export const backendUri = "http://ec2-15-206-212-133.ap-south-1.compute.amazonaws.com:5006"
