import "./Base.css"

import { useEffect, useState, useRef } from "react"
import { gapi } from "gapi-script"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"

import MSLogoutButton from "../../screens/Login/components/ms/MSLogoutButton"
import GoogleLogoutButton from "../../screens/Login/components/google/GoogleLogoutButton"

import datamatics_logo from "../../assets/images/DatamaticsBlack_KaiSDLC_line.svg"
import profile from "../../assets/images/profile.svg"
import projects from "../../assets/images/projects.svg"
import right from "../../assets/images/right_2.svg"

import { clientId } from "../../constants"
import Button from "../Button/Button"

export default function Base(props) {
        const { accounts } = useMsal()
        const isAuthenticated = useIsAuthenticated()

        const [givenName, setGivenName] = useState("")
        const [familyName, setFamilyName] = useState("")
        const [profileIcon, setProfileIcon] = useState()
        console.log("profileIcon".profileIcon)
        const [email, setEmail] = useState("")
        useEffect(() => {
                if (accounts[0]?.tenantId)
                {
                        sessionStorage.setItem("profileObj", accounts[0].homeAccountId)
                        setGivenName(accounts[0].name.split(" ")[0])
                        setFamilyName(accounts[0].name.split(" ")[1])
                        // setProfileIcon(profile?.getImageUrl())
                        setEmail(accounts[0].username)
                }
                else
                        gapi.load("auth2", () => {
                                let auth2 = gapi.auth2.init({ clientId: clientId, scope: "" })

                                auth2.then(() => {
                                        let isSignedIn = auth2.isSignedIn.get()
                                        let currentUser = auth2.currentUser.get()

                                        if (isSignedIn)
                                        {
                                                let profile = currentUser?.getBasicProfile()

                                                sessionStorage.setItem("profileObj", profile.getId())

                                                setGivenName(profile?.getGivenName())
                                                setFamilyName(profile?.getFamilyName())
                                                setProfileIcon(profile?.getImageUrl())
                                                setEmail(profile?.getEmail())
                                        }
                                        else
                                        {
                                                sessionStorage.removeItem("profileObj")
                                                sessionStorage.removeItem("role")
                                                window.location.href = "/"
                                        }
                                })
                        })
                // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const [showProfile, setShowProfile] = useState(false)
        const profileRef = useRef(null)
        const handleProfileIconClick = () => {
                setShowProfile((p) => (p === showProfile ? !showProfile : showProfile))
        }
        function imageExists (url){
                var image = new Image()
                image.src = url

                if (!image.complete)
                        return false
                else if (image.height === 0)
                        return false
                return true
        }

        return (
                <div className="base">
                        <div className="header">
                                {!props?.separate || props?.separate === false ?
                                        <nav className="header_left">
                                                <li className="nav_item" onClick={() => (window.location.href = "/sdlc")}>SDLC</li>

                                                {sessionStorage.getItem("role") === "1" ?
                                                        <li className="nav_item" onClick={() => (window.location.href = "/setup")}>Setup</li>
                                                        :
                                                        ""
                                                }

                                                <li className="nav_item suite">
                                                        <span className="nav_item_label" onClick={() => (window.location.href = "/tools")}>AI Suite</span>
                                                        {props?.label ?
                                                                <>
                                                                        <img className="nav_item_arrow" src={right} alt="Icon" />
                                                                        <span className="nav_item_tool" onClick={() => window.location.reload()}>
                                                                                <img className="nav_item_icon" src={props?.icon} alt="Icon"/>
                                                                                <span className="nav_item_label">{props?.label}</span>
                                                                        </span>
                                                                </>
                                                                :
                                                                ""
                                                        }
                                                </li>
                                        </nav>
                                        :
                                        <div className="header_left_separatedLogo">
                                                <img src={props?.icon} alt="Icon" />
                                        </div>
                                }

                                {!props?.separate || props?.separate === false ?
                                        <div className="header_center">
                                                <div className="header_datamatics_container">
                                                        <img src={datamatics_logo} alt="Datamatics" className="header_datamatics"/>
                                                </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="header_right">
                                        <div className={`header_profileIconContainer ${showProfile}`} onClick={handleProfileIconClick}>
                                                <div className="header_profileIcon_info">
                                                        <div className="header_profileIcon_name">{givenName.split(" ")[0]}</div>

                                                        <div className="header_profileIcon_role">
                                                                {sessionStorage.getItem("role") === "1" ?
                                                                        "Admin, TruSDLCGen.ai"
                                                                        :
                                                                        sessionStorage.getItem("role") === "2" ?
                                                                                "Manager"
                                                                                :
                                                                                "Team Member"
                                                                }
                                                        </div>
                                                </div>

                                                {imageExists(profileIcon) ?
                                                        <img src={profileIcon} className="header_profileIcon" alt="Profile"/>
                                                        :
                                                        <div className="header_profileIconFallback">{givenName.charAt(0)}</div>
                                                }
                                        </div>
                                </div>

                                {showProfile && (
                                        <div className="profile-box" ref={profileRef}>
                                                <Button onClick={() => (window.location.href = "/projects")} icon={projects}>Projects</Button>
                                                {isAuthenticated ?
                                                        <MSLogoutButton setShowProfile={setShowProfile} url={`/${props?.label ?? ""}`}/>
                                                        :
                                                        <GoogleLogoutButton url={`/${props?.label ?? ""}`} />
                                                }
                                        </div>
                                )}
                        </div>

                        <div className="bodyWrapper">
                                <div className="contentWrapper">{props.children}</div>
                        </div>
                </div>
        )
}
