import "./Dashboard.css";

import { useState } from "react";

import Base from "../../../../components/Base/Base";
import analysis from "../../../../assets/images/Analysis.svg";
import design from "../../../../assets/images/Design.svg";
import development from "../../../../assets/images/Development.svg";
import testing from "../../../../assets/images/Testing.svg";
import deployment from "../../../../assets/images/Deployment.svg";
import maintenance from "../../../../assets/images/Maintenance.svg";

export default function Dashboard(props) {
  const icons = [
    analysis,
    design,
    development,
    testing,
    deployment,
    maintenance,
  ];

  const mainPhases = [
    ...new Set(props?.phases.map((phase) => phase.mainLabel)),
  ];
  const [selectedMainPhase, setSelectedMainPhase] = useState(
    props?.phases?.filter((phase) => phase.index.split("")[1] !== "*")[
      sessionStorage.getItem("phase") - 1
    ]?.mainLabel
  );

  const subPhases = props?.phases
    ?.filter((phase) => phase.index.split("")[1] !== "*")
    .map((phase) => phase.subLabel);

  const displayPhases = props?.phases?.filter(
    (phase) => phase.mainLabel === selectedMainPhase
  );
  const [selectedDisplayIndex, setselectedDisplayIndex] = useState(
    displayPhases
      .map((p) => p.subLabel)
      .indexOf(subPhases[sessionStorage.getItem("phase") - 1])
  );

  const mainPhaseClickHandler = (phase) => {
    setSelectedMainPhase(phase);
    displayPhaseClickHandler(
      props?.phases?.filter((p) => p.mainLabel === phase)[0],
      0
    );
  };

  const displayPhaseClickHandler = (phase, index) => {
    setselectedDisplayIndex(index);
    if (phase.index.split("")[1] !== "*") {
      sessionStorage.setItem("phase", subPhases.indexOf(phase.subLabel) + 1);
      window.location.reload();
    } else props?.setSelectedFeature(phase.name);
  };

  return (
    <Base label={props?.module} icon={props?.icon} separate={props?.separate}>
      <div className="Dash_container">
        {!props.isEstimations ? (
          <div className="Dash_tabs">
            {!props?.separate ? (
              <div className="Dash_mainTabs">
                {mainPhases.map((phase, index) => (
                  <div
                    key={index}
                    className={`Dash_mainTab ${
                      phase ===
                      props?.phases?.filter(
                        (phase) => phase.index.split("")[1] !== "*"
                      )[sessionStorage.getItem("phase") - 1].mainLabel
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => mainPhaseClickHandler(phase)}
                  >
                    <img
                      className="Dash_mainTab_icon"
                      src={icons[index]}
                      alt={phase}
                    />
                    <span>{index + 1}</span>
                    <span>{phase}</span>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="Dash_subTabs">
              {props?.phases
                ?.filter(
                  (phase) =>
                    phase.mainLabel ===
                    props?.phases?.filter(
                      (phase) => phase.index.split("")[1] !== "*"
                    )[sessionStorage.getItem("phase") - 1]?.mainLabel
                )
                .map((phase, i) => (
                  <div
                    key={i}
                    className={`Dash_subtab ${
                      i ===
                      props?.phases
                        ?.filter(
                          (phase) =>
                            phase.mainLabel ===
                            props?.phases?.filter(
                              (phase) => phase.index.split("")[1] !== "*"
                            )[sessionStorage.getItem("phase") - 1].mainLabel
                        )
                        .map((p) => p.subLabel)
                        .indexOf(subPhases[sessionStorage.getItem("phase") - 1])
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => displayPhaseClickHandler(phase, i)}
                  >
                    {phase.subLabel}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="Dash_content">
          <div className="Dash_content_body">{props.children}</div>
        </div>
      </div>
    </Base>
  );
}
