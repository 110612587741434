import "./Setup.css";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Base from "../../components/Base/Base";
import TextLoader from "../../components/TextLoader/TextLoader";

import { backendUri } from "../../constants";

import apple from "../../assets/images/mock_project_logos/Datamatics_logo.png";
import dropdown from "../../assets/images/downarrow.svg";
import add from "../../assets/images/add.png";
import save from "../../assets/images/verified.png";

export default function Setup() {
  const location = useLocation();
  const [projectName, setProjectName] = useState(
    location?.state?.selectedProject?.ProjectName ?? ""
  );
  const [clientName, setClientName] = useState(
    location?.state?.selectedProject?.ClientName ?? ""
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const projectTypeLabels = ["Fixed price", "TnM (Time & Material)"];
  const [selectedProjectType, setSelectedProjectType] = useState(
    location?.state?.selectedProject
      ? location?.state?.selectedProject.TypeOfProject === "Fixed price"
        ? 0
        : 1
      : 0
  );
  const [optionsVisibility, setOptionsVisibility] = useState("hide");
  function handleSelectionClick() {
    setOptionsVisibility((prevState) =>
      prevState === "show" ? "hide" : "show"
    );
  }
  function handleOptionClick(index) {
    setSelectedProjectType(index);
    setOptionsVisibility("hide");
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const [fromDate, setFromDate] = useState(
    formatDate(
      location?.state?.selectedProject
        ? formatDate(location?.state?.selectedProject.ProjectDurationStart)
        : new Date()
    )
  );
  const [toDate, setToDate] = useState(
    formatDate(
      location?.state?.selectedProject
        ? formatDate(location?.state?.selectedProject.ProjectDurationEnd)
        : new Date()
    )
  );
  const handleFromDateClick = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDateClick = (e) => {
    setToDate(e.target.value);
  };
  useEffect(() => {
    if (fromDate > toDate) setToDate(fromDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  const [frontendLang, setFrontendLang] = useState(
    location?.state?.selectedProject?.FrontendLang ?? ""
  );
  const [backendLang, setBackendLang] = useState(
    location?.state?.selectedProject?.BackendLang ?? ""
  );

  const [collectionName, setCollectionName] = useState(
    location?.state?.selectedProject?.CollectionName ?? ""
  );
  const [ragDocument, setRagDocument] = useState("");

  const [localManagers, setLocalManagers] = useState(
    location?.state?.selectedProject?.ProjectManagers ?? []
  );
  const [localManagerName, setLocalManagerName] = useState("");
  const [localManagerEmail, setLocalManagerEmail] = useState("");
  const phaseNames = [
    "reQAPhase",
    "HLD",
    "LLD",
    "COD",
    "TESTPLAN",
    "QA",
    "AUTO",
    "DEVOP",
    "MANUAL",
    "CODD",
  ];
  const [localManagerAuthorizedPhases, setLocalManagerAuthorizedPhases] =
    useState([]);
  const handleManagerCheckClick = (phaseName) => {
    localManagerAuthorizedPhases.includes(phaseName)
      ? setLocalManagerAuthorizedPhases((p) =>
          phaseNames.filter((phase) =>
            p.filter((item) => item !== phaseName).includes(phase)
          )
        )
      : setLocalManagerAuthorizedPhases((p) =>
          phaseNames.filter((phase) => [...p, phaseName].includes(phase))
        );
  };

  const [showAddManager, setShowAddManager] = useState(true);
  const handleAddManagerClick = () => {
    const existingManager = projects.find((project) =>
      project.ProjectManagers.some(
        (manager) => manager.ProjectManagerEmail === localManagerEmail
      )
    );

    const existingMember = projects.find((project) =>
      project.TeamMembers.some(
        (member) => member.TeamMemberEmail === localManagerEmail
      )
    );

    if (existingManager) {
      alert(
        `Manager email ${localManagerEmail} already exists in project: ${existingManager.ProjectName}`
      );
    } else if (existingMember) {
      alert(
        `Team member email ${localManagerEmail} already exists in project: ${existingMember.ProjectName}`
      );
    } else {
      if (
        localManagerName &&
        localManagerEmail &&
        localManagerAuthorizedPhases.length
      ) {
        localManagers.push({
          ProjectManagerName: localManagerName,
          ProjectManagerEmail: localManagerEmail,
          ProjectManagerPhases: localManagerAuthorizedPhases,
        });
        setLocalManagerName("");
        setLocalManagerEmail("");
        setLocalManagerAuthorizedPhases([]);
      }
    }
  };
  const [selectedManager, setSelectedManager] = useState("");
  const handleManagerClick = (i) => {
    setSelectedManager(i);
    setLocalManagerName(localManagers[i].ProjectManagerName);
    setLocalManagerEmail(localManagers[i].ProjectManagerEmail);
    setLocalManagerAuthorizedPhases(
      localManagers[i].ProjectManagerPhases?.length
        ? localManagers[i].ProjectManagerPhases
        : []
    );
    setShowAddManager(false);
  };
  const handleSaveManagerClick = () => {
    if (
      localManagerName &&
      localManagerEmail &&
      localManagerAuthorizedPhases.length
    ) {
      setLocalManagers((p) => [
        ...p.slice(0, selectedManager),
        {
          ProjectManagerName: localManagerName,
          ProjectManagerEmail: localManagerEmail,
          ProjectManagerPhases: localManagerAuthorizedPhases,
        },
        ...p.slice(selectedManager + 1),
      ]);
      setShowAddManager(true);
      setLocalManagerName("");
      setLocalManagerEmail("");
      setLocalManagerAuthorizedPhases([]);
      setSelectedManager("");
    }
  };
  const handleDeleteManagerClick = () => {
    setLocalManagers((p) => [
      ...p.slice(0, selectedManager),
      ...p.slice(selectedManager + 1),
    ]);
    setShowAddManager(true);
    setLocalManagerName("");
    setLocalManagerEmail("");
    setLocalManagerAuthorizedPhases([]);
    setSelectedManager("");
  };
  const handleCancelManagerClick = () => {
    setShowAddManager(true);
    setLocalManagerName("");
    setLocalManagerEmail("");
    setLocalManagerAuthorizedPhases([]);
    setSelectedManager("");
  };

  const [localMembers, setLocalMembers] = useState(
    location?.state?.selectedProject?.TeamMembers ?? []
  );
  const [localMemberName, setLocalMemberName] = useState("");
  const [localMemberEmail, setLocalMemberEmail] = useState("");

  const [localAuthorizedPhases, setLocalAuthorizedPhases] = useState([]);
  const handleCheckClick = (phaseName) => {
    localAuthorizedPhases.includes(phaseName)
      ? setLocalAuthorizedPhases((p) =>
          phaseNames.filter((phase) =>
            p.filter((item) => item !== phaseName).includes(phase)
          )
        )
      : setLocalAuthorizedPhases((p) =>
          phaseNames.filter((phase) => [...p, phaseName].includes(phase))
        );
  };

  const [showAddMember, setShowAddMember] = useState(true);
  const handleAddMemberClick = () => {
    const existingMember = projects.find((project) =>
      project.TeamMembers.some(
        (member) => member.TeamMemberEmail === localMemberEmail
      )
    );

    const existingManager = projects.find((project) =>
      project.ProjectManagers.some(
        (manager) => manager.ProjectManagerEmail === localMemberEmail
      )
    );

    if (existingMember) {
      alert(
        `Team member email ${localMemberEmail} already exists in project: ${existingMember.ProjectName}`
      );
    } else if (existingManager) {
      alert(
        `Manager email ${localMemberEmail} already exists in project: ${existingManager.ProjectName}`
      );
    } else {
      if (localMemberName && localMemberEmail && localAuthorizedPhases.length) {
        localMembers.push({
          TeamMemberName: localMemberName,
          TeamMemberEmail: localMemberEmail,
          TeamMemberPhases: localAuthorizedPhases,
        });
        setLocalMemberName("");
        setLocalMemberEmail("");
        setLocalAuthorizedPhases([]);
      }
    }
  };

  const [selectedMember, setSelectedMember] = useState("");
  const handleMemberClick = (i) => {
    setSelectedMember(i);
    setLocalMemberName(localMembers[i].TeamMemberName);
    setLocalMemberEmail(localMembers[i].TeamMemberEmail);
    setLocalAuthorizedPhases(
      localMembers[i].TeamMemberPhases?.length
        ? localMembers[i].TeamMemberPhases
        : []
    );
    setShowAddMember(false);
  };
  const handleSaveMemberClick = () => {
    if (localMemberName && localMemberEmail && localAuthorizedPhases.length) {
      setLocalMembers((p) => [
        ...p.slice(0, selectedMember),
        {
          TeamMemberName: localMemberName,
          TeamMemberEmail: localMemberEmail,
          TeamMemberPhases: localAuthorizedPhases,
        },
        ...p.slice(selectedMember + 1),
      ]);
      setShowAddMember(true);
      setLocalMemberName("");
      setLocalMemberEmail("");
      setLocalAuthorizedPhases([]);
      setSelectedMember("");
    }
  };
  const handleDeleteMemberClick = () => {
    setLocalMembers((p) => [
      ...p.slice(0, selectedMember),
      ...p.slice(selectedMember + 1),
    ]);
    setShowAddMember(true);
    setLocalMemberName("");
    setLocalMemberEmail("");
    setLocalAuthorizedPhases([]);
    setSelectedMember("");
  };
  const handleCancelMemberClick = () => {
    setShowAddMember(true);
    setLocalMemberName("");
    setLocalMemberEmail("");
    setLocalAuthorizedPhases([]);
    setSelectedMember("");
  };

  const [saveEnable, setSaveEnable] = useState(false);
  useEffect(() => {
    if (
      projectName &&
      clientName &&
      projectTypeLabels[selectedProjectType] &&
      fromDate &&
      toDate &&
      frontendLang &&
      backendLang &&
      // collectionName &&
      // ragDocument &&
      localManagers.length &&
      localMembers.length
    )
      setSaveEnable(true);
    else setSaveEnable(false);
  }, [
    backendLang,
    clientName,
    fromDate,
    frontendLang,
    localMembers,
    localManagers,
    projectName,
    projectTypeLabels,
    selectedProjectType,
    toDate,
    // collectionName,
    // ragDocument,
  ]);
  const [loading, setLoading] = useState(false);
  const [ragSuccess, setRagSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const uploadRAG = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

    const formdata = new FormData();
    const fileInput = document.getElementById("Projects_ragInput");
    formdata.append("file", fileInput.files[0]);
    formdata.append("collection_name", collectionName);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${backendUri}/api/rag/upload`, requestOptions)
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        setRagSuccess(JSON.parse(res).staus);
        if (JSON.parse(res).staus) setFileName(JSON.parse(res).fileName);
        else alert(JSON.parse(res).message);
      })
      .catch(() => {
        setLoading(false);
        setRagSuccess(false);
      });
  };

  const uploadProjectDetails = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

      var objData = {
        ProjectName: projectName,
        ProjectDescription: "AI project",
        ProjectDurationStart: fromDate,
        ProjectDurationEnd: toDate,
        FrontendLang: frontendLang,
        BackendLang: backendLang,
        TypeOfProject: projectTypeLabels[selectedProjectType],
        ClientName: clientName,
        ProjectManagers: localManagers,
        Role: "PROJECT_MANAGER",
        // "CollectionName": collectionName,
        // "Reference_doc": ragDocument,
        TeamMembers: localMembers,
      };

      if (location?.state?.selectedProject)
        objData.ProjectId = location?.state?.selectedProject.ProjectId;
      if (collectionName) objData.CollectionName = collectionName;
      if (fileName) objData.Reference_doc = fileName;

      var raw = JSON.stringify(objData);

      fetch(
        `${backendUri}/api/project/${
          location?.state?.selectedProject ? "update" : "create"
        }`,
        {
          method: location?.state?.selectedProject ? "PUT" : "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      )
        .then((response) => response.text())
        .then((res) =>
          JSON.parse(res).message === "Entered user details already exist"
            ? alert(
                JSON.parse(res).message,
                ":\n",
                JSON.parse(res).Data.join("\n")
              )
            : (window.location.href = "/projects")
        )
        .catch((error) => console.log("Following error occurred: \n", error));
    } catch (error) {
      console.log("Following error occurred: \n", error);
    }
  };

  useEffect(() => {
    if (ragSuccess) uploadProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ragSuccess]);
  const handleSaveClick = () => {
    if (saveEnable)
      if (
        collectionName &&
        document.getElementById("Projects_ragInput").files[0]
      )
        uploadRAG();
      else uploadProjectDetails();
    else console.log("Save disabled.");
  };

  const [projects, setProjects] = useState([]);

  const retrieveProjects = () => {
    var myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

    var formdata = new FormData();
    formdata.append("isMembers", 1);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: formdata,
      redirect: "follow",
    };

    fetch(`${backendUri}/api/project/get`, requestOptions)
      .then((response) => response.text())
      .then((result) => setProjects(JSON.parse(result).data.projects))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      retrieveProjects();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Base>
      <TextLoader loading={loading} loaderMessage={"Uploading RAG"} />
      <div className="Setup">
        <div className="Setup_container">
          <div className="Projects_label1">
            {location?.state?.selectedProject
              ? "Edit project"
              : "Create project"}
          </div>
          <div className="Setup_right_col2">
            <div className="Projects_fieldsRow">
              <div className="Projects_img">
                <img className="Projects_projectLogo" src={apple} alt="logo" />
              </div>
            </div>
            <div className="Projects_fieldsRow">
              <div className="Projects_fieldBox">
                <div className="Projects_fieldLabel">
                  Project name <span className="red-star">*</span>
                </div>
                <input
                  className="Projects_fieldEntry"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>
              <div className="Projects_fieldBox right">
                <div className="Projects_fieldLabel">
                  Client name <span className="red-star">*</span>
                </div>
                <input
                  className="Projects_fieldEntry"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>
            </div>
            <div className="Projects_fieldsRow">
              <div className="Projects_fieldBox">
                <div className="Projects_fieldLabel">
                  Project type <span className="red-star">*</span>
                </div>
                <div
                  className="Phases_selectedDocument setup"
                  onClick={handleSelectionClick}
                >
                  <div className="Phases_selectedDocumentTitle">
                    {projectTypeLabels[selectedProjectType]}
                  </div>
                  <img
                    src={dropdown}
                    className={`Phases_selectedDocumentDropdown ${
                      optionsVisibility === "show" ? "show" : "hide"
                    }`}
                    alt="Expand"
                  />
                </div>
                <div
                  className={`Phases_documentOptions ${optionsVisibility} setup`}
                >
                  {projectTypeLabels.map((phase, key) => (
                    <div
                      key={key}
                      className="Phases_documentOption"
                      onClick={() => handleOptionClick(key)}
                    >
                      {phase}
                    </div>
                  ))}
                </div>
              </div>
              <div className="Projects_fieldBox right">
                <div className="Projects_fieldLabel">
                  Project duration <span className="red-star">*</span>
                </div>
                <div className="Projects_dateRange">
                  <input
                    type="date"
                    className="Projects_fieldEntry date"
                    value={fromDate}
                    onChange={(e) => handleFromDateClick(e)}
                  />
                  <input
                    type="date"
                    className="Projects_fieldEntry date right"
                    value={toDate}
                    min={fromDate}
                    onChange={(e) => handleToDateClick(e)}
                  />
                </div>
              </div>
            </div>
            <div className="Projects_fieldsRow">
              <div className="Projects_fieldBox">
                <div className="Projects_fieldLabel">
                  Frontend language <span className="red-star">*</span>
                </div>
                <input
                  className="Projects_fieldEntry"
                  value={frontendLang}
                  onChange={(e) => setFrontendLang(e.target.value)}
                />
              </div>
              <div className="Projects_fieldBox right">
                <div className="Projects_fieldLabel">
                  Backend language <span className="red-star">*</span>
                </div>
                <input
                  className="Projects_fieldEntry"
                  value={backendLang}
                  onChange={(e) => setBackendLang(e.target.value)}
                />
              </div>
            </div>
            <div className="Projects_fieldsRow">
              <div className="Projects_fieldBox">
                <div className="Projects_fieldLabel">Collection name</div>
                <input
                  className="Projects_fieldEntry"
                  value={collectionName}
                  onChange={(e) => setCollectionName(e.target.value)}
                />
              </div>
              <div className="Projects_fieldBox right">
                <div className="Projects_fieldLabel">
                  RAG reference document
                </div>
                <div className="Projects_fieldEntry rag">
                  <input
                    type="file"
                    id="Projects_ragInput"
                    onChange={(e) => setRagDocument(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
            <div className="Projects_team managers">
              <div className="Projects_fieldsRow">
                <div className="Projects_fieldBox">
                  <div className="Projects_fieldLabel">
                    Project manager name<span className="red-star">*</span>
                  </div>
                  <input
                    className="Projects_fieldEntry"
                    value={localManagerName}
                    onChange={(e) => setLocalManagerName(e.target.value)}
                  />
                </div>
                <div className="Projects_fieldBox right">
                  <div className="Projects_fieldLabel">
                    Project manager email <span className="red-star">*</span>
                  </div>
                  <input
                    className="Projects_fieldEntry"
                    value={localManagerEmail}
                    onChange={(e) => setLocalManagerEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="Projects_authorizedPhasesWrapper">
                <div className="Projects_fieldLabel">
                  Authorized phases <span className="red-star">*</span>
                </div>
                <div className="Projects_authorizedPhases">
                  {phaseNames.map((phaseName, i) => (
                    <div className="Projects_authorizedPhase" key={i}>
                      <input
                        type="checkbox"
                        checked={localManagerAuthorizedPhases.includes(
                          phaseName
                        )}
                        onChange={() => handleManagerCheckClick(phaseName)}
                        className="Projects_authorizedPhaseCheckbox"
                      />
                      <div className="Projects_authorizedPhaseLabel">
                        {phaseName}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="Projects_addTeamMember">
                {showAddManager ? (
                  <button
                    className="SN_button"
                    onClick={handleAddManagerClick}
                    style={{ backgroundColor: "red" }}
                  >
                    Add
                    <img src={add} className="icons" alt="Add" />
                  </button>
                ) : (
                  <div className="Projects_buttonsGroup">
                    <button
                      className="SN_button"
                      onClick={handleSaveManagerClick}
                    >
                      Save
                    </button>
                    <button
                      className="SN_button Projects_rightButton"
                      onClick={handleDeleteManagerClick}
                    >
                      Delete
                    </button>
                    <button
                      className="SN_button Projects_rightButton"
                      onClick={handleCancelManagerClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <div className="Projects_fieldLabel">Project managers list</div>
              <div className="Projects_membersBox">
                {localManagers.map((mappedManager, i) => (
                  <div
                    className={`Projects_membersRow`}
                    key={i}
                    onClick={() => handleManagerClick(i)}
                  >
                    <div className="Projects_fieldBox">
                      <div
                        className={`Projects_member ${
                          selectedManager === i ? "selected" : ""
                        }`}
                      >
                        {mappedManager.ProjectManagerName}
                      </div>
                    </div>
                    <div className="Projects_fieldBox right">
                      <div
                        className={`Projects_member ${
                          selectedManager === i ? "selected" : ""
                        }`}
                      >
                        {mappedManager.ProjectManagerEmail}
                      </div>
                    </div>
                    <div className="Projects_fieldBox right">
                      <div
                        className={`Projects_member ${
                          selectedManager === i ? "selected" : ""
                        }`}
                      >
                        {mappedManager.ProjectManagerPhases?.length
                          ? mappedManager.ProjectManagerPhases.map(
                              (phase) => phase + ", "
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="Projects_team">
              <div className="Projects_fieldsRow">
                <div className="Projects_fieldBox">
                  <div className="Projects_fieldLabel">
                    Team member name <span className="red-star">*</span>
                  </div>
                  <input
                    className="Projects_fieldEntry"
                    value={localMemberName}
                    onChange={(e) => setLocalMemberName(e.target.value)}
                  />
                </div>
                <div className="Projects_fieldBox right">
                  <div className="Projects_fieldLabel">
                    Team member email <span className="red-star">*</span>
                  </div>
                  <input
                    className="Projects_fieldEntry"
                    value={localMemberEmail}
                    onChange={(e) => setLocalMemberEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="Projects_authorizedPhasesWrapper">
                <div className="Projects_fieldLabel">
                  Authorized phases <span className="red-star">*</span>
                </div>
                <div className="Projects_authorizedPhases">
                  {phaseNames.map((phaseName, i) => (
                    <div className="Projects_authorizedPhase" key={i}>
                      <input
                        type="checkbox"
                        checked={localAuthorizedPhases.includes(phaseName)}
                        onChange={() => handleCheckClick(phaseName)}
                        className="Projects_authorizedPhaseCheckbox"
                      />
                      <div className="Projects_authorizedPhaseLabel">
                        {phaseName}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="Projects_addTeamMember">
                {showAddMember ? (
                  <button
                    className="SN_button"
                    onClick={handleAddMemberClick}
                    style={{ backgroundColor: "red" }}
                  >
                    Add
                    <img src={add} className="icons" alt="Add" />
                  </button>
                ) : (
                  <div className="Projects_buttonsGroup">
                    <button
                      className="SN_button"
                      onClick={handleSaveMemberClick}
                    >
                      Save
                      <img src={save} className="icons" alt="Save" />
                    </button>
                    <button
                      className="SN_button Projects_rightButton"
                      onClick={handleDeleteMemberClick}
                    >
                      Delete
                    </button>
                    <button
                      className="SN_button Projects_rightButton"
                      onClick={handleCancelMemberClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <div className="Projects_fieldLabel">Team members list</div>
              <div className="Projects_membersBox">
                {localMembers.map((mappedMember, i) => (
                  <div
                    className={`Projects_membersRow`}
                    key={i}
                    onClick={() => handleMemberClick(i)}
                  >
                    <div className="Projects_fieldBox">
                      <div
                        className={`Projects_member ${
                          selectedMember === i ? "selected" : ""
                        }`}
                      >
                        {mappedMember.TeamMemberName}
                      </div>
                    </div>
                    <div className="Projects_fieldBox right">
                      <div
                        className={`Projects_member ${
                          selectedMember === i ? "selected" : ""
                        }`}
                      >
                        {mappedMember.TeamMemberEmail}
                      </div>
                    </div>
                    <div className="Projects_fieldBox right">
                      <div
                        className={`Projects_member ${
                          selectedMember === i ? "selected" : ""
                        }`}
                      >
                        {mappedMember.TeamMemberPhases?.length
                          ? mappedMember.TeamMemberPhases.map(
                              (phase) => phase + ", "
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="Projects_right_button">
              <button
                className="SN_button"
                onClick={handleSaveClick}
                style={{ backgroundColor: "green", color: "white" }}
              >
                Save
                <img src={save} className="icons" alt="Save" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
