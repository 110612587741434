import "./Phases.css";

import { useEffect, useState } from "react";

import Base from "../../components/Base/Base";
import Phase from "./Phase/Phase";

import dropdown from "../../assets/images/downarrow.svg";
import { backendUri } from "../../constants";

export default function Phases() {
  useEffect(() => sessionStorage.removeItem("phase"), []);

  const phaseNames = [
    "reQAPhase",
    "HLD",
    "LLD",
    "COD",
    "TESTPLAN",
    "QA",
    "AUTO",
    "DEVOP",
    "MANUAL",
    "CODD",
  ];
  const phaseLabels = [
    "Requirement Analysis",
    "High Level Design",
    "Low Level Design",
    "Coding",
    "Test Planning",
    "Test Cases",
    "Automation Testing",
    "DevOps",
    "User Manual",
    "BRE",
  ];
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [optionsVisibility, setOptionsVisibility] = useState("hide");
  function handleSelectionClick() {
    setOptionsVisibility((prevState) =>
      prevState === "show" ? "hide" : "show"
    );
  }
  function handleOptionClick(index) {
    setSelectedDocument(index);
    setOptionsVisibility("hide");
  }

  const [historyRecords, setHistoryRecords] = useState([]);
  const retrieveHistory = () => {
    var myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${backendUri}/api/history/get/all?phase=${phaseNames[selectedDocument]}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => setHistoryRecords(JSON.parse(result).data))
      .catch((error) => console.error(error));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => retrieveHistory(), [selectedDocument]);

  const [selectedHistoryRecord, setSelectedHisoryRecord] = useState(0);
  const handleHistoryRecordClick = (i) => setSelectedHisoryRecord(i);

  return (
    <Base>
      <div className="Phases_pad">
        <div className="Phases_container">
          <div className="Phases_row_1">
            <div className="Phases_phasesContainer">
              <div className="Phases_wrapperDiv">
                <div className="Phases_title">Phases</div>
                <div className="Phases_phases">
                  <div className="Phases_wrapperRow">
                    <Phase phase={1} />
                    <Phase phase={2} />
                    <Phase phase={3} />
                    <Phase phase={4} />
                    <Phase phase={5} />
                    <Phase phase={6} />
                    <Phase phase={7} />
                    <Phase phase={8} />
                    <Phase phase={9} />
                    <Phase phase={10} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
