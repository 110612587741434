import "./Phase.css";

import ra from "./images/requirementsanalysis.svg";
import hld from "./images/highleveldesign.svg";
import lld from "./images/lowleveldesign.svg";
import c from "./images/coding.svg";
import qa from "./images/qa.svg";
import d from "./images/devops.svg";
import { useNavigate } from "react-router-dom";

export default function Phase(props) {
  // const phases = [
  //   "Requirements Analysis",
  //   "High Level Design",
  //   "Low Level Design",
  //   "Coding",
  //   "QA",
  //   "Automation Testing",
  //   "Test Planning",
  //   "DevOps",
  //   "User Manual",
  //   "BRE",
  // ];

  const phases = [
    "Requirement Analysis",
    "High Level Design",
    "Low Level Design",
    "Coding",
    "Test Planning",
    "Test Cases",
    "Automation Testing",
    "DevOps",
    "User Manual",
    "BRE",
  ];
  const images = [ra, hld, hld, c, qa, qa, qa, d, lld, lld];

  const navigate = useNavigate();

  function handleButtonClick() {
    sessionStorage.setItem("phase", props.phase);
    navigate(`/sdlc`);
  }

  return (
    <div className="Phase_container" onClick={handleButtonClick}>
      <img className="Phase_image" src={images[props.phase - 1]} alt="Icon" />
      <div className="Phase_title">{phases[props.phase - 1]}</div>
      {/* <input
        type="button"
        className="Phase_button"
        value="Start Process"
        onClick={handleButtonClick}
      /> */}
    </div>
  );
}
