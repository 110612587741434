// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Figtree-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/LibreBaskerville-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/RobotoMono-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Montserrat';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: 'Figtree';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: 'Baskerville';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: 'Roboto';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4CAAyD;AAC3D;;AAEA;EACE,gCAAgC;EAChC,4CAAgE;AAClE;;AAEA;EACE,sBAAsB;EACtB,4CAAsD;AACxD;;AAEA;EACE,0BAA0B;EAC1B,4CAAqD;AACvD;;AAEA;EACE,qBAAqB;EACrB,4CAAyD;AAC3D;;AAEA;EACE,mCAAmC;EACnC,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Montserrat';\n  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);\n}\n\n@font-face {\n  font-family: 'Montserrat-Italic';\n  src: url(./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf);\n}\n\n@font-face {\n  font-family: 'Figtree';\n  src: url(./assets/fonts/Figtree-VariableFont_wght.ttf);\n}\n\n@font-face {\n  font-family: 'Baskerville';\n  src: url(./assets/fonts/LibreBaskerville-Regular.ttf);\n}\n\n@font-face {\n  font-family: 'Roboto';\n  src: url(./assets/fonts/RobotoMono-VariableFont_wght.ttf);\n}\n\nbody {\n  -webkit-font-smoothing: antialiased;\n  line-height: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
