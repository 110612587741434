import "./MSLogoutButton.css"

import { useMsal } from "@azure/msal-react"

import microsoft from '../../../../assets/images/microsoft.svg'

export default function MSLogoutButton (props) {
        const { instance, accounts } = useMsal();

        function handleLogoutClick () {
                sessionStorage.removeItem("profileObj")
                sessionStorage.removeItem("role")
                props.setShowProfile(p => p === true ? false : true)
                instance.logoutRedirect({
                        account: instance.getAccountByHomeId(accounts[0].homeAccountId),
                        postLogoutRedirectUri: window.location.href.includes("/sdlc") || window.location.href.includes("/tools") ? "/" : window.location.href.includes("/trutest") ? "/test" : "/bre"
                })
        }

        return (
                <div className="MSLogout_container"
                        onClick={handleLogoutClick}
                >
                        <div className="MSLogout_iconContainer"><img className="MSLogout_icon" src={microsoft} alt="MS"/></div>
                        <div className="MSLogout_label">Sign out</div>
                </div>
        )
}
