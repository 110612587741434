import "./Chatbot.css"

import { useRef, useState } from "react"
import ReactMarkdown from "react-markdown";

import chat from "../../assets/images/chat.svg"
import ask from "../../assets/images/ask.svg"
import cross from "../../assets/images/cross.svg"
import logo from "../../assets/images/Datamatics_KaiCopilot_stacked.svg"

import { backendUri } from "../../constants"

export default function Chatbot (props) {
        const [chatbotOpen, setChatbotOpen] = useState(false)

        const inputText = useRef("")

        const [messages, setMessages] = useState(["How may I help you?"])

        function handleSendClick () {
                const text = inputText.current.value;
                inputText.current.value = ""

                setMessages(p => [...p, text, "Generating response..."])

                try {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

                        const raw = JSON.stringify({
                                "phase": props?.phase,
                                "question": text
                        });

                        const requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow"
                        };

                        fetch(`${backendUri}/api/chat/phase`, requestOptions)
                                .then((response) => response.text())
                                .then((result) => setMessages(p => [...p.slice(0, -1), JSON.parse(result).data.response]))
                                .catch((error) => console.error(error));
                } catch (error) {
                        console.log("error:", error)
                }
        }

        return (
                <div className="Chatbot">
                        {chatbotOpen ?
                                <div className="Chatbot_bgGrad">
                                        <div className="Chatbot_window">
                                                <div className="Chatbot_header">
                                                        <img className="Chatbot_icon" src={logo} alt="Chat"/>
                                                        {/* <div className="Chatbot_header_title">TruCopilot</div> */}
                                                        <img src={cross} onClick={() => setChatbotOpen(p => !p)} alt="Close"/>
                                                </div>
                                                <div className="Chatbot_messages">
                                                        {messages.map((message, i) => <div key={i} className="Chatbot_message"><ReactMarkdown>{message}</ReactMarkdown></div>)}
                                                </div>
                                                <div className="Chatbot_input">
                                                        <input type="text" ref={inputText} className="Chatbot_inputBox" placeholder="Type your question here..."/>
                                                        <button className="Chatbot_submit" onClick={handleSendClick}>
                                                                <img src={ask} alt="Ask"/>
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                                :
                                ""
                        }
                        {!chatbotOpen ?
                                <div className="Chatbot_button_container">
                                        <button className="Chatbot_button" onClick={() => setChatbotOpen(p => !p)}>
                                                <img className="Chatbot_icon" src={chat} alt="Chat"/>
                                        </button>
                                </div>
                                :
                                ""
                        }
                </div>
        )
}