import './Footer.css'

import { useEffect, useState } from 'react'

import settings from "../../assets/images/settingsgear.svg"
import downarrow from "../../assets/images/downarrow.svg"
import attachment from "../../assets/images/attachment.svg"
import update from "../../assets/images/update.svg"
import generate from "../../assets/images/right.svg"

export default function Footer (props) {
        const [showParameters, setShowParameters] = useState(false)
        const handleParametersClick = () => setShowParameters(p => !p)

        const [enableRAG, setEnableRAG] = useState(false)
        const handleRAGClick = () => setEnableRAG(p => !p)
        const [temperature, setTemperature] = useState(0.5)
        const [LLM, setLLM] = useState("")
        const [context, setContext] = useState("")
        useEffect(() => {
                props?.parameterValues({RAG: enableRAG, temperature: temperature, LLM: LLM, context: context})
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [enableRAG, temperature, LLM, context])

        const handleTemperatureChange = e => setTemperature(e.target.value)

        const handleFileChange = e => {
                props?.handleUploadClick(e.target.files)
        }

        return (
                <>
                        {showParameters ?
                                <div className="Parameters">
                                        <div className='Footer_title'>
                                                <img src={settings} alt="Settings"/>
                                                <div>Advanced settings</div>
                                        </div>
                                        <div className='Parameters_row'>
                                                {props?.parameterKeys?.includes("RAG") ?
                                                        <div className='Parameters_column RAG'>
                                                                <label className='Parameters_label'>Enable RAG</label>
                                                                <div className={`Parameters_RAG_outer ${enableRAG}`} onClick={handleRAGClick}>
                                                                        <div className={`Parameters_RAG_inner ${enableRAG}`}/>
                                                                </div>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                {props?.parameterKeys?.includes("Temperature") ?
                                                        <div className='Parameters_column right'>
                                                                <label className='Parameters_label'>Temperature</label>
                                                                <div className="Parameters_temperature" style={{"--m": `${temperature}`}}>
                                                                        <input
                                                                                type="range"
                                                                                className="SN_tempSlider"
                                                                                value={temperature}
                                                                                onChange={e => handleTemperatureChange(e)}
                                                                                step={0.1}
                                                                                max={1}
                                                                                list="SN_tempSlider_marks"
                                                                        />
                                                                        <div className="Parameters_temperature_thumb">{temperature}</div>
                                                                </div>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                        </div>

                                        <div className='Parameters_row'>
                                                {props?.parameterKeys?.includes("LLM") ?
                                                        <div className='Parameters_column'>
                                                                <div className="Footer_dropdown">
                                                                        <div className="SN_dropdown_border">
                                                                                <select className="SN_dropdownSelect" value={LLM} onChange={(e) => setLLM(e.target.value)}>
                                                                                        <option value="">Select LLM</option>
                                                                                        <option value="GPTVISION">GPTVISION</option>
                                                                                        <option value="GEMINI">GEMINI</option>
                                                                                </select>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                {props?.parameterKeys?.includes("Context") ?
                                                        <div className='Parameters_column right'>
                                                                <input type="text" className='Footer_context' placeholder="Enter context" value={context} onChange={e => setContext(e.target.value)}/>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                        </div>
                                </div>
                                :
                                ""
                        }

                        <div className='Footer'>
                                <button className={`Footer_button left ${showParameters}`} onClick={handleParametersClick}>
                                {/* <button className="Footer_button left"> */}
                                        <img className={`Footer_button_icon ${showParameters}`} src={showParameters ? downarrow : settings} alt="Settings"/>
                                </button>

                                <input type="file" id="SN_upload" style={{display: "none"}} onClick={e => e.target.value = null} onChange={e => handleFileChange(e)} multiple/>
                                <label htmlFor="SN_upload" className="Footer_button">
                                        <img className="Footer_button_icon" src={attachment} alt="Attachment" data-tooltip-id="file-tooltip" data-tooltip-content="Upload document"/>
                                        <div className="Footer_button_label">Upload</div>
                                </label>

                                <button className="Footer_button update" onClick={props?.handleUpdateClick}>
                                        <img className="Footer_button_icon" src={update} alt="Update"/>
                                        <label className="Footer_button_label">Update</label>
                                </button>

                                <button className="Footer_button right generate invert" onClick={props?.handleGenerateClick}>
                                        <img className="Footer_button_icon" src={generate} alt="Generate"/>
                                        <label className="Footer_button_label">Generate</label>
                                </button>
                        </div>
                </>
        )
}
