import './MSLoginButton.css'

import { useMsal } from "@azure/msal-react";

import { backendUri } from '../../../../constants'
import { loginRequest } from "../../../../msauth";

import microsoft from '../../../../assets/images/microsoft.svg'

export default function MSLoginButton (props) {
        const { instance } = useMsal()

        function handleLoginClick () {
                instance.loginPopup(loginRequest)
                .then(res=>{
                        var myHeaders = new Headers()
                        myHeaders.append("Content-Type", "application/json")

                        var raw = JSON.stringify({
                                UserEmailId: res.account.username,
                                UserGoogleId: res.account.homeAccountId,
                                FirstName: res.account.name.split(" ")[0],
                                // LastName: res.account.name.split(" ")[1],
                                Authentication: "Microsoft",
                        })

                        var requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow",
                        }

                        fetch(`${backendUri}/api/login`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                                sessionStorage.setItem("role", JSON.parse(result).data.RoleId)
                                sessionStorage.setItem("profileObj", res.account.homeAccountId)
                                window.location.href = props?.url
                        })
                        .catch(e => {
                                console.log("Fetch error: ", e)
                                props.setErrorText("Login failed. Please try again.")
                        })
                })
                .catch(e => {
                        console.log("Login error: ", e)
                        props.setErrorText("Login failed. Please try again.")
                })
        }

        return (
                <div className="MSLogin_container" onClick={handleLoginClick}>
                        <div className="MSLogin_iconContainer"><img className="MSLogin_icon" src={microsoft} alt="MS"/></div>
                        <div className="MSLogin_label">Sign in with Microsoft</div>
                </div>
        )
}
