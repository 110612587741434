import "./StartNew.css"
import { useEffect, useRef, useState } from "react"
import { Tooltip } from "react-tooltip"
import { Document, HeadingLevel, Paragraph, Packer} from "docx"
import { saveAs } from "file-saver"
import CustomMarkdown from "../../components/CustomMarkdown/CustomMarkdown"
import Dashboard from "./components/Dashboard/Dashboard"
import TextLoader from "../../components/TextLoader/TextLoader"
import Footer from "../../components/Footer/Footer"
import Chatbot from "../../components/ChatBot/Chatbot"
import settings from "../../assets/images/settingsgear.svg"
import attachment from "../../assets/images/attachment.svg"
import download from "../../assets/images/download.svg"
import update from "../../assets/images/update.svg"
import generate from "../../assets/images/right.svg"
import proceed from "../../assets/images/proceed.svg"
import history from "../../assets/images/history.svg"
import edit from "../../assets/images/edit.svg"
import tick from "../../assets/images/tick.svg"
import Delete from "../../assets/images/delete.png"
import word from "../../assets/images/word.svg"
import plaintext from "../../assets/images/plaintext.svg"
import advantage1 from "../../assets/images/advantage1.svg"
import advantage2 from "../../assets/images/advantage2.svg"
import advantage3 from "../../assets/images/advantage3.svg"
import advantage4 from "../../assets/images/advantage4.svg"
import advantage5 from "../../assets/images/advantage5.svg"
import TruTest_icon from "../../assets/images/Datamatics_KaiTest_stacked.svg"
import { backendUri } from "../../constants"

export default function StartNew(props)
{
        let phases
        if(props?.module) {
                if(props?.module === "test")
                        phases = [
                                {index: "4a", name: "TESTPLAN",   subLabel: "Test Planning",              mainLabel: "Testing",      input: "Business Requirements document"},
                                {index: "4b", name: "QA",         subLabel: "Test Cases",                 mainLabel: "Testing",      input: "Business Requirements document"},
                                {index: "4c", name: "AUTO",       subLabel: "Automation Testing",         mainLabel: "Testing",      input: "QA document"},
                        ]
        }
        else phases = [
                {index: "1a", name: "reQAPhase",  subLabel: "Requirement Analysis",       mainLabel: "Analysis",     input: "Requirements list",               output: "Business Requirements Document"},
                {index: "2a", name: "HLD",        subLabel: "High Level Design",          mainLabel: "Design",       input: "Business Requirements Document",  output: "High-Level Design Document"},
                {index: "2b", name: "LLD",        subLabel: "Low Level Design",           mainLabel: "Design",       input: "High-Level Design Document",      output: "Low-Level Design Document"},
                {index: "3a", name: "COD",        subLabel: "Coding",                     mainLabel: "Development",  input: "Low-Level Design Document",       output: "Coding Document"},
                {index: "4a", name: "TESTPLAN",   subLabel: "Test Planning",              mainLabel: "Testing",      input: "Business Requirements Document",  output: "Test Plan Document"},
                {index: "4b", name: "QA",         subLabel: "Test Cases",                 mainLabel: "Testing",      input: "Business Requirements Document",  output: "QA Document"},
                {index: "4c", name: "AUTO",       subLabel: "Automation Testing",         mainLabel: "Testing",      input: "QA Document",                     output: "Automation Script Document"},
                {index: "5a", name: "DEVOP",      subLabel: "DevOps",                     mainLabel: "Deployment",   input: "Business Requirements Document",  output: "DevOps Document"},
                {index: "6a", name: "MANUAL",     subLabel: "User Manual",                mainLabel: "Maintenance",  input: "Business Requirements Document",  output: "User Manual"},
        ]

        const phaseIcons = [
                ["test", TruTest_icon],
                ["dummy", settings]
        ]

        const subPhases = phases?.filter(phase => phase.index.split('')[1] !== "*")
        const phaseNames = subPhases?.map(phase => phase.name)
        const phaseLabels = subPhases?.map(phase => phase.subLabel)

        const [selectedFeature, setSelectedFeature] = useState(null)

        let currentPhase = 0;
        sessionStorage.getItem("phase") ?
                currentPhase = parseInt(sessionStorage.getItem("phase")) - 1
                :
                sessionStorage.setItem("phase", currentPhase + 1)
        const totalPhases = phaseNames?.length
        const progress = ((currentPhase + 1) / totalPhases) * 100

        const [input, setInput] = useState("")

        const [loading, setLoading] = useState(false);
        const [loaderMessage, setLoaderMessage] = useState("Generating Requirement Analysis document")

        const [parameterKeys, setParameterKeys] = useState(["RAG", "LLM", "Context", "Temperature"])
        const [parameterValues, setParameterValues] = useState([])

        const [showContent, setShowContent] = useState("Hide")
        const handleToggle = () => {
                setShowContent(prev => prev === "Hide" ? "Show" : "Hide")
                setTemp(null)
                setSelectedLLM("")
        }
        useEffect(() => (showContent === "Hide" ? setTemp() : setTemp(0.5)), [showContent])

        const [showContentRag, setShowContentRag] = useState("Hide");
        const [useRag, setUseRag] = useState(false);
        const handleToggleRag = () => {
                setUseRag(showContentRag === "Hide")
                setShowContentRag(prev => prev === "Hide" ? "Show" : "Hide")
        }
        useEffect(() => showContentRag === "Hide" ? setTemp() : setTemp(0.5), [showContentRag])

        const [temp, setTemp] = useState()

        const [selectedLLM, setSelectedLLM] = useState("")

        const frontendLangs = ["HTML, CSS, JS", "React", "Angular", "JQuery", "Vue", "Solid"]
        const [selectedFrontend, setSelectedFrontend] = useState(frontendLangs[0]);

        const backendLangs = ["Java", "PHP", ".Net", "Ruby"]
        const [selectedBackend, setSelectedBackend] = useState(backendLangs[0]);

        const [files, setFiles] = useState([])
        const handleFileChange = e => {
                setInput("")
                setFiles(e.target.files)
                const fileListDiv = document.getElementById("fileList")
                fileListDiv.innerHTML = ""
                const files = e.target.files
                for (let i = 0; i < files.length; i++) {
                        const fileName = files[i].name
                        const listItem = document.createElement("div")
                        listItem.textContent = fileName
                        fileListDiv.appendChild(listItem)
                }
        }

        const getLatestInput = (phase = parseInt(sessionStorage.getItem("phase"))-1) => {
                document.getElementById("fileList").innerHTML = "";

                var myHeaders = new Headers();
                myHeaders.append("profileObj", sessionStorage.getItem("profileObj"))
                myHeaders.append("Content-Type", "application/json")

                const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow"
                }

                fetch(`${backendUri}/api/input?phase=${phaseNames[phase]}`, requestOptions)
                .then((response) => response.json())
                .then((result) => setInput(result.data))
                .catch((error) => console.error("Error:", error))
        }

        const [apiResponse, setApiResponse] = useState("")
        const [apiResponseCost, setApiResponseCost] = useState("")

        const bottomScrollReference = useRef()
        const [showCursor, setShowCursor] = useState(false)
        const handleStream = async (url, headers, body, retries = 5, delay = 1000) => {
                try {
                        outputRef?.current?.scroll({top: 0})

                        const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: body
                        });

                        if (response.status === 429) {
                                if (retries > 0) {
                                        console.warn(`Rate limit exceeded, retrying in ${delay}ms...`);
                                        await new Promise(resolve => setTimeout(resolve, delay));
                                        return handleStream(url, headers, body, retries - 1, delay * 2); // Retry with exponential backoff
                                }
                                else
                                        throw new Error('Max retries reached. Too many requests.');
                        }

                        setApiResponse("")

                        const reader = response.body.getReader();
                        const decoder = new TextDecoder();
                        let buffer = '';

                        while (true) {
                                setShowCursor(true)
                                const { done, value } = await reader.read();
                                if (done) {
                                        const lines = buffer.split('\n')
                                        const line = lines[lines.length-1].trim()
                                        setApiResponse(prev => prev + '\n' + line + '\n')
                                        break;
                                }

                                buffer += decoder.decode(value, { stream: true });
                                const lines = buffer.split('\n');

                                for (let i = 0; i < lines.length - 1; i++) {
                                        const line = lines[i].trim();
                                        if (line) {
                                                setApiResponse(prev => prev + line + '\n');
                                                bottomScrollReference?.current?.scrollIntoView()
                                        }
                                }

                                buffer = lines[lines.length - 1];
                        }

                        setShowCursor(false)
                        setTimeout(()=>bottomScrollReference?.current?.scrollIntoView(), 250)
                        setTimeout(()=>outputRef?.current?.scroll({top: 0, behavior: "smooth"}), 1750)
                        retrieveHistory()
                }
                catch (err) {
                        console.error('An error occurred:', err);
                }
        };

        // overrideInput to force-pass latest value on proceed; useState is unreliable
        const handleConfirmClick = async (targetPhase, isUpdate, isProceed = false) => {
                setApiResponse("```Analyzing your input...```")

                try {
                        var myHeaders = new Headers()
                        myHeaders.append("profileObj", sessionStorage.getItem("profileObj"))

                        var formdata = new FormData()

                        var context = document.getElementById("context")?.value ?? ""
                        var temp = document.getElementById("userInputTemp").value

                        if (!isProceed) {
                                if (files.length > 0)
                                        for (let i = 0; i < files.length; i++)
                                                formdata.append("file", files[i]);
                                else if (input)
                                        formdata.append("userInput", input);
                        }

                        if (targetPhase === "COD") formdata.append("temp", "0.5");
                        else formdata.append("temp", temp);

                        let selectedPhase = selectedFeature ?? targetPhase;
                        if (selectedFeature)
                                setLoaderMessage(`${phases[phases.map(phase => phase.name).indexOf(selectedFeature)].subLabel} in progress`)

                        formdata.append("phase", props.isEstimations ? "MANUALTIME" : selectedPhase);
                        if (!isUpdate && !props.isEstimations) formdata.append("generate", "GEN");

                        if (selectedLLM === "GPTVISION") formdata.append("chatgpt", selectedLLM);
                        else if (selectedLLM === "GEMINI") formdata.append("google", selectedLLM);
                        if (context) formdata.append("context", context);
                        if (useRag) {
                                formdata.append("rag", "true");
                        }
                        if (sessionStorage.getItem("role") !== "1")
                                formdata.append("ProjectName", projects[0].ProjectName);

                        handleStream(`${backendUri}/api/upload`, myHeaders, formdata)
                } catch (error) {
                        console.error("Error:", error);
                        setApiResponse("Error occurred while making the request");
                } finally {
                        // setLoading(false);
                }
        };

        const handleProceedClick = () => {
                sessionStorage.setItem("phase", parseInt(sessionStorage.getItem("phase")) + 1)

                getLatestInput()

                handleConfirmClick(phaseNames[currentPhase + 1], false, true)
        }

        const [estimationsResponse, setEstimationsResponse] = useState("");
        useEffect(() => {
                if (estimationsResponse) setLoading(false);
        }, [estimationsResponse]);
        const handleEstimationsClick = () => {
                setTimeout(() => setLoading(true), 500);
                setLoaderMessage("Getting estimates");
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
                        const raw = JSON.stringify({
                                temp: 0.5,
                                document: apiResponse,
                                frontend: selectedFrontend,
                                backend: selectedBackend,
                        });
                        const requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow",
                        };

                        fetch(`${backendUri}/api/manual/estimation`, requestOptions)
                                .then((response) => response.text())
                                .then((result) =>
                                        setEstimationsResponse(JSON.parse(result).data.response)
                                )
                                .catch((error) => console.error(error));
                } catch (error) {
                        console.log("error:", error);
                } finally {
                        setLoading(false);
                }
        };

        const [selectedHistoryRecord, setSelectedHisoryRecord] = useState(0);
        const [historyDropdownOpen, setHistoryDropdownOpen] = useState(false);
        function toggleHistoryDropdown() {
                setHistoryDropdownOpen(p => !p);
        }
        function handleHistoryRecordClick(index) {
                setSelectedHisoryRecord(index);

                document.getElementById("fileList").innerHTML = "";
                setInput(historyRecords[index].Input ?? "")
                setApiResponse(historyRecords[index].Output);

                setHistoryDropdownOpen(false);
        }
        const [historyRecords, setHistoryRecords] = useState([]);
        const retrieveHistory = () => {
                if (!selectedFeature) {
                        var myHeaders = new Headers();
                        myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(`${backendUri}/api/history/get/all?phase=${phaseNames[currentPhase]}`, requestOptions)
                                .then((response) => response.text())
                                .then((result) => setHistoryRecords(JSON.parse(result).data))
                                .catch((error) => console.error(error));
                }
        };
        const outputRef = useRef()
        useEffect(() => {
                if (!props.isEstimations) {
                        retrieveHistory()
                        setOutputEdit(false)
                        setHistoryDropdownOpen(false)
                        setShowExportOptions(false)
                        outputRef?.current?.scroll({top: 0})
                }
                else if (apiResponseCost && !estimationsResponse)
                        handleEstimationsClick();
                // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [apiResponseCost, currentPhase]);
        useEffect(() => {
                if (historyRecords?.length && !apiResponse)
                        handleHistoryRecordClick(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [historyRecords]);
        const handleDeleteHistoryClick = (index) => {
                setHistoryDropdownOpen(false);
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

                const raw = JSON.stringify({
                        phase: phaseNames[currentPhase],
                        Id: historyRecords[index].Id,
                });

                const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                };

                fetch(`${backendUri}/api/history`, requestOptions)
                        .then(() => window.location.reload())
                        .catch((error) => console.error(error));
        };

        const [showExportOptions, setShowExportOptions] = useState(false);
        const toggleExportOptions = () =>
                setShowExportOptions((p) => (p === true ? false : true));
        const handleDownloadClick = (format) => {
                toggleExportOptions();
                if (apiResponse) {
                        if (format === "docx") {
                                const lines = apiResponse.replaceAll("**", "").split("\n");
                                const docx = new Document({
                                        sections: [{
                                                children: lines.map(line =>
                                                        line.startsWith("# ") ? new Paragraph({text: line.slice(2), heading: HeadingLevel.HEADING_1})
                                                        : line.startsWith("## ") ? new Paragraph({text: line.slice(3), heading: HeadingLevel.HEADING_2})
                                                        : line.startsWith("### ") ? new Paragraph({text: line.slice(4), heading: HeadingLevel.HEADING_3})
                                                        : line.startsWith("#### ") ? new Paragraph({text: line.slice(5), heading: HeadingLevel.HEADING_4})
                                                        : line.startsWith("##### ") ? new Paragraph({text: line.slice(6), heading: HeadingLevel.HEADING_5})
                                                        : line.startsWith("###### ") ? new Paragraph({text: line.slice(7), heading: HeadingLevel.HEADING_6})
                                                        : line.startsWith("- ") ? new Paragraph({ text: line.slice(2), bullet: { level: 0 } })
                                                        : new Paragraph({ text: line })
                                                )
                                        }]
                                })

                                Packer.toBlob(docx).then(blob => saveAs(blob, `${phaseLabels[currentPhase]} document.docx`))
                        } else {
                                const blob = new Blob([apiResponse], { type: "text/plain" });

                                const a = document.createElement("a");
                                a.href = window.URL.createObjectURL(blob);
                                a.download = ` ${phaseLabels[currentPhase]} [RAW].txt`;

                                a.click();

                                window.URL.revokeObjectURL(a.href);
                        }
                }
        };

        const [outputEdit, setOutputEdit] = useState(false);

        const [projects, setProjects] = useState([]);
        const retrieveProjects = () => {
                var myHeaders = new Headers();
                myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

                var formdata = new FormData();
                formdata.append("isMembers", 1);

                const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        // body: formdata,
                        redirect: "follow",
                };

                fetch(`${backendUri}/api/project/get`, requestOptions)
                        .then((response) => response.text())
                        .then((result) => setProjects(JSON.parse(result).data.projects))
                        .catch((error) => console.log(error));
        };
        useEffect(() => retrieveProjects(), []);

        useEffect(() => {
                document.getElementById("userInputText").value = "";
                setApiResponse("")
                setApiResponseCost("")
        }, [selectedFeature])

        const phaseInfo = [
                "Allowed inputs:<br/>Text<br/>Multiple files (.txt, .pdf, .docx, .py, .xlsx)<br/>Images (.png, .jpg, .jpeg)<br/>JSON<br/>Audio (.mp3)",
                "Allowed inputs:<br/>Text<br/>Multiple files (.txt, .pdf, .docx, .py)<br/>Images (.png, .jpg, .jpeg)",
                "Allowed inputs:<br/>Text<br/>Multiple files (.txt, .pdf, .docx, .py)<br/>Images (.png, .jpg, .jpeg)",
                "Allowed inputs:<br/>Text/Code<br/>Multiple files (.txt, .pdf, .docx, .py)<br/>JSON<br/>Images (.png, .jpg, .jpeg) [For Plugin UI and JSON extraction]",
                "Allowed inputs:<br/>Text/Code<br/>Multiple files (.txt, .pdf, .docx, .py)<br/>Images (.png, .jpg, .jpeg)<br/>JSON",
                "Allowed inputs:<br/>Text/Code<br/>Multiple files (.txt, .pdf, .docx, .py, .xlsx)<br/>Images (.png, .jpg, .jpeg)<br/>JSON",
                "Allowed inputs:<br/>Text/Code<br/>Multiple files (.txt, .pdf, .docx, .py)<br/>JSON<br/>Images (.png, .jpg, .jpeg)<br/>Test cases",
                "Allowed inputs:<br/>Text<br/>Multiple files (.txt, .pdf, .docx, .py)",
                "Allowed inputs:<br/>Text<br/>Multiple files (.txt, .pdf, .docx, .py)",
                "Allowed inputs:<br/>Code<br/>Multiple code files (.txt, .pdf, .docx, .py)",
        ]

        return (
                <Dashboard
                        separate={props?.module ? true : false}
                        icon={props?.module ? phaseIcons.filter(phaseSet => phaseSet[0] === props?.module)[0][1] : null}
                        progress={progress}
                        apiResponseCost={apiResponseCost}
                        isEstimations={props.isEstimations}
                        phases={phases}
                        setSelectedFeature={setSelectedFeature}
                >
                        <TextLoader loading={loading} loaderMessage={loaderMessage}/>

                        <div className="StartNew">
                                <Tooltip id="file-tooltip" />
                                <Tooltip id="temp-tooltip" />
                                <Tooltip id="generate-tooltip" />
                                <Tooltip id="proceed-tooltip" />
                                <Tooltip id="retry-tooltip" />
                                <Tooltip id="export-tooltip" />

                                <div className="SN_partition">
                                        <div className="SN_header">
                                                <span>Input</span>
                                                <span></span>
                                                <span>{phases[currentPhase]?.input}</span>
                                        </div>

                                        <div className="SN_inputContainer">
                                                <div id="fileList" className="input_file_upload"/>
                                                <textarea
                                                        className="SN_requirementText"
                                                        id="userInputText"
                                                        disabled={(phaseNames[currentPhase] === "COD" && selectedFeature === "UIDEV") || selectedFeature === "IMGJSON"}
                                                        placeholder={`Paste/Upload your ${phases[currentPhase]?.input}`}
                                                        onChange={e => setInput(e.target.value)}
                                                        value={input}
                                                />
                                        </div>

                                        <div className={`SN_${showContent}Content`}>
                                                {currentPhase === 0 ?
                                                        <div className="SN_toggleContainer">
                                                                <div className="SN_requirementsLabel">RAG document</div>
                                                                <div className={`SN_toggle_switch ${showContentRag}`} onClick={handleToggleRag}>
                                                                        <div className={`SN_toggle_switch_circle ${showContentRag}`}/>
                                                                </div>
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                {(["reQAPhase", "HLD", "LLD", "QA", "TESTPLAN", "COD", "AUTO"].includes(phaseNames[currentPhase]) || props.isEstimations) ?
                                                        <div className="SN_dropdownsContainer">
                                                                <div className="SN_dropdown">
                                                                        <div className="SN_dropdown_border">
                                                                                <select className="SN_dropdownSelect" value={selectedLLM} onChange={(e) => setSelectedLLM(e.target.value)}>
                                                                                        <option value="" disabled>Select LLM</option>
                                                                                        <option value={null}>Default</option>
                                                                                        <option value="GPTVISION">GPTVISION</option>
                                                                                        <option value="GEMINI">GEMINI</option>
                                                                                </select>
                                                                        </div>
                                                                </div>

                                                                {!(phaseNames[currentPhase] === "COD" && (selectedFeature === "UIDEV" || selectedFeature === "IMGJSON")) ?
                                                                        <div className="SN_contextContainer">
                                                                                <div className="SN_contextBorder">
                                                                                        <textarea className="SN_context" id="context" placeholder="Context"/>
                                                                                </div>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                }

                                                                {props.isEstimations ?
                                                                        <>
                                                                                <div className="SN_dropdown">
                                                                                        <div className="SN_requirementsLabel">Frontend</div>
                                                                                        <div className="SN_dropdown_border">
                                                                                                <select className="SN_dropdownSelect" value={selectedFrontend} onChange={(e) => setSelectedFrontend(e.target.value)}>
                                                                                                        {frontendLangs.map((lang, i) =>
                                                                                                                <option value={lang} key={i}>{lang}</option>
                                                                                                        )}
                                                                                                </select>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="SN_dropdown">
                                                                                        <div className="SN_requirementsLabel">Backend</div>
                                                                                        <div className="SN_dropdown_border">
                                                                                                <select className="SN_dropdownSelect" value={selectedBackend} onChange={(e) => setSelectedBackend(e.target.value)}>
                                                                                                        {backendLangs.map((lang, i) => <option value={lang} key={i}>{lang}</option>
                                                                                                        )}
                                                                                                </select>
                                                                                        </div>
                                                                                </div>
                                                                        </>
                                                                        :
                                                                        ""
                                                                }
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                <div className="SN_tempLabelContainer">
                                                        <div className="SN_requirementsLabel">Temperature</div>
                                                        <div className="SN_tempBarAndMarks">
                                                                <input
                                                                        type="range"
                                                                        id="userInputTemp"
                                                                        className="SN_tempSlider"
                                                                        value={temp || ""}
                                                                        onChange={(e) => setTemp(e.target.value)}
                                                                        step={0.1}
                                                                        max={1}
                                                                        list="SN_tempSlider_marks"
                                                                />
                                                                <datalist id="SN_tempSlider_marks">
                                                                        {Array(11).fill(1).map((e, i) =>
                                                                                <option key={i} className="SN_tempSlider_mark" label={(i / 10).toFixed(1)} value={i / 10}/>
                                                                        )}
                                                                </datalist>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="SN_footer">
                                                <button className="SN_footer_button left" onClick={handleToggle}>
                                                        <img src={settings} alt="Settings"/>
                                                </button>

                                                <button className="SN_footer_button" data-tooltip-id="file-tooltip" data-tooltip-html={phaseInfo[currentPhase]}>
                                                        <label htmlFor="SN_upload" className="SN_footer_button">
                                                                <img src={attachment} alt="Attachment"/>
                                                                <div className="Button_label">Upload</div>
                                                        </label>
                                                        <input type="file" id="SN_upload" style={{ display: "none" }} onClick={e => e.target.value = null} onChange={e => handleFileChange(e)} multiple/>
                                                </button>

                                                <button className={`SN_footer_button update ${!apiResponse ? "disable" : ""}`} onClick={() => handleConfirmClick(phaseNames[currentPhase], true)}>
                                                        <img src={update} alt="Update"/>
                                                        <label className="Button_label">Update</label>
                                                </button>

                                                <button className={`SN_footer_button right generate invert ${!input && !files.length ? "disable" : ""}`} onClick={() => handleConfirmClick(phaseNames[currentPhase], false)}>
                                                        <img src={generate} alt="Generate"/>
                                                        <label className="Button_label">Generate</label>
                                                </button>
                                        </div>
                                </div>

                                <div className="SN_partition">
                                        {!apiResponse && props?.module ?
                                                <div className="SN_advantagesContainer">
                                                        <div className='SN_advantages_header'>
                                                                Kai<span className='SN_advantages_para_bre'>Test</span> Advantages
                                                        </div>
                                                        <div className="SN_advantagesList">
                                                                <div className="SN_advantage">
                                                                        <img src={advantage1} alt="Advantage"/>
                                                                        <div className="SN_advantageContent">Accelerates testing processes and project timelines</div>
                                                                </div>
                                                                <div className="SN_advantage">
                                                                        <img src={advantage2} alt="Advantage"/>
                                                                        <div className="SN_advantageContent">Eliminates human errors</div>
                                                                </div>
                                                                <div className="SN_advantage">
                                                                        <img src={advantage3} alt="Advantage"/>
                                                                        <div className="SN_advantageContent">Provides comprehensive coverage of user scenarios</div>
                                                                </div>
                                                                <div className="SN_advantage">
                                                                        <img src={advantage4} alt="Advantage"/>
                                                                        <div className="SN_advantageContent">High quality outputs thanks to robust prompts</div>
                                                                </div>
                                                                <div className="SN_advantage">
                                                                        <img src={advantage5} alt="Advantage"/>
                                                                        <div className="SN_advantageContent">Reduces reliance and spending on human resources</div>
                                                                </div>
                                                        </div>
                                                </div>
                                                :
                                                <>
                                                        <div className="SN_header">
                                                                <span>Output</span>
                                                                <span></span>
                                                                <span>{phases[currentPhase]?.output}</span>
                                                        </div>

                                                        <div className="SN_outputBox">
                                                                {apiResponse ?
                                                                        <Chatbot phase={phaseNames[currentPhase]}/>
                                                                        :
                                                                        ""
                                                                }
                                                                {outputEdit === false ?
                                                                        <div className="SN_outputContent" ref={outputRef}>
                                                                                <CustomMarkdown>{`${apiResponse} ${showCursor ? "█" : ""}`}</CustomMarkdown>
                                                                                <span ref={bottomScrollReference}/>
                                                                        </div>
                                                                        :
                                                                        <textarea className="SN_outputContent edit" value={apiResponse} onChange={(e) => setApiResponse(e.target.value)}/>
                                                                }
                                                        </div>

                                                        <div className={`SN_footer ${!apiResponse ? "disable" : ""}`}>
                                                                <button className="SN_footer_button left">
                                                                        <img src={outputEdit ? tick : edit} onClick={() => setOutputEdit(p => !p)} alt="Edit"/>
                                                                </button>

                                                                <button className="SN_footer_button history" onClick={toggleHistoryDropdown}>
                                                                        <img src={history} alt="History"/>
                                                                        <label className="Button_label">History</label>
                                                                        {historyRecords?.length && historyDropdownOpen ?
                                                                                <div className="SN_historyDropdown">
                                                                                        {historyRecords?.map((historyRecord, i) =>
                                                                                                <div key={i} className={`SN_historyRecord ${i === selectedHistoryRecord ? "selected" : ""}`}>
                                                                                                        <div className="SN_historyRecord_index" onClick={() => handleHistoryRecordClick(i)}>{i + 1}</div>
                                                                                                        <div className="SN_historyRecord_fileName" onClick={() => handleHistoryRecordClick(i)}>
                                                                                                                {phaseNames[sessionStorage.getItem("phase") - 1]}_{historyRecord.created_on}.docx
                                                                                                        </div>
                                                                                                        <img src={Delete} alt="Delete" className="icons" onClick={() => handleDeleteHistoryClick(i)}/>
                                                                                                </div>
                                                                                        )}
                                                                                </div>
                                                                                :
                                                                                ""
                                                                        }
                                                                </button>

                                                                <button className="SN_footer_button download" onClick={toggleExportOptions}>
                                                                        <img src={download} alt="Download"/>
                                                                        <label className="Button_label">Download</label>
                                                                        {showExportOptions ?
                                                                                <div className="SN_exportOptionsContainer">
                                                                                        <div className="SN_exportOption" onClick={() => handleDownloadClick("docx")}>
                                                                                                <img src={word} alt="Word"/>
                                                                                                Word Doc
                                                                                        </div>
                                                                                        <div className="SN_exportOption" onClick={() => handleDownloadClick("txt")}>
                                                                                                <img src={plaintext} alt="Plaintext"/>
                                                                                                Plaintext
                                                                                        </div>
                                                                                </div>
                                                                                :
                                                                                ""
                                                                        }
                                                                </button>

                                                                <button
                                                                        className={`SN_footer_button right proceed invert ${currentPhase + 1 === phaseNames.length ? "disable" : ""}`}
                                                                        onClick={()=>handleProceedClick(phaseNames[currentPhase + 1])}
                                                                >
                                                                        <img src={proceed} alt="Proceed"/>
                                                                        <label className="Button_label">Proceed</label>
                                                                </button>
                                                        </div>
                                                </>
                                        }
                                </div>
                        </div>
                </Dashboard>
        )
}
