import { Routes, Route } from 'react-router-dom'

import Login from './screens/Login/Login'
import Setup from './screens/Setup/Setup'
import Projects from './screens/Projects/Projects'
import Phases from './screens/Phases/Phases'
import StartNew from "./screens/StartNew/StartNew"
import Tools from './screens/Tools/Tools'
import Estimates from './screens/Estimates/Estimates'
import TruBRE from './screens/TruBRE/TruBRE'
import CodeToBRD from './screens/CodeToBRD/CodeToBRD'
import PluginUI from './screens/PluginUI/PluginUI'
import CodeConversion from './screens/CodeConversion/CodeConversion'
import ExtractJSON from './screens/ExtractJSON/ExtractJSON'
import ErrorFinding from './screens/ErrorFinding/ErrorFinding'
import DefectAnalysis from './screens/DefectAnalysis/DefectAnalysis'
import DefectRCA from './screens/DefectRCA/DefectRCA'
import Reports from './screens/Login/components/Reports/Reports'
import ResumeFormatter from './screens/ResumeFormatter/ResumeFormatter'

export default function App()
{
        return  <Routes>
                <Route path="/" element={<Login name="TruSDLC"/>}/>
                <Route path="/login" element={<Login name="TruSDLC"/>}/>
                <Route path="/bre" element={<Login name="TruBRE"/>}/>
                <Route path="/test" element={<Login name="TruTest"/>}/>
                <Route path="/setup" element={<Setup/>}/>
                <Route path="/projects" element={<Projects/>}/>
                <Route path="/phases" element={<Phases/>} />
                <Route path="/sdlc" element={<StartNew/>}/>
                <Route path="/reports" element={<Reports/>}/>
                <Route path="/trutest" element={<StartNew module="test"/>}/>
                <Route path="/estimations" element={<StartNew isEstimations={true}/>}/>
                <Route path="/phase2" element={<phase2/>}/>
                <Route path="/phase3" element={<phase3/>}/>
                <Route path="/phase4" element={<phase4/>}/>
                <Route path="/tools" element={<Tools/>}/>
                <Route path="/tools/estimates" element={<Estimates/>}/>
                <Route path="/tools/kaibre" element={<TruBRE/>}/>
                <Route path="/kaibre" element={<TruBRE separate/>}/>
                <Route path="/tools/codetobrd" element={<CodeToBRD/>}/>
                <Route path="/tools/uidevelopment" element={<PluginUI/>}/>
                <Route path="/tools/codeconversion" element={<CodeConversion/>}/>
                <Route path="/tools/extractjson" element={<ExtractJSON/>}/>
                <Route path="/tools/errorfinding" element={<ErrorFinding/>}/>
                <Route path="/tools/defectanalysis" element={<DefectAnalysis/>}/>
                <Route path="/tools/defectrca" element={<DefectRCA/>}/>
                <Route path="/tools/resumeformatter" element={<ResumeFormatter/>}/>
        </Routes>
}
