// import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
        auth: {
                clientId: "ef3db678-a61b-4455-bde4-299848d90206",
                authority: "https://login.microsoftonline.com/c8cd4a0c-01a5-4a15-8c43-823a203014a8",
                redirectUri: "https://trusdlc-genai.techjini.com/",
        },
        cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
        },
        // system: {
        //         loggerOptions: {
        //                 loggerCallback: (level, message, containsPii) => {
        //                         if (containsPii) {
        //                                 return;
        //                         }
        //                         switch (level) {
        //                                 case LogLevel.Error:
        //                                         console.error(message);
        //                                         return;
        //                                 case LogLevel.Info:
        //                                         console.info(message);
        //                                         return;
        //                                 case LogLevel.Verbose:
        //                                         console.debug(message);
        //                                         return;
        //                                 case LogLevel.Warning:
        //                                         console.warn(message);
        //                                         return;
        //                                 default:
        //                                         return;
        //                         }
        //                 }
        //         }
        // }
};

export const loginRequest = {
        scopes: ["User.Read"]
};

export const graphConfig = {
        graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};