import { GoogleLogout } from "react-google-login";

import Button from "../../../../components/Button/Button";

import google from "../../../../assets/images/google.svg"

import { clientId } from "../../../../constants";

export default function GoogleLogoutButton (props)
{
        const onSuccess = () => {
                sessionStorage.removeItem("profileObj")
                sessionStorage.removeItem("role")
                window.location.href = window.location.href.includes("/sdlc") || window.location.href.includes("/tools") ? "/" : window.location.href.includes("/trutest") ? "/test" : "/bre"
        }

        return (
                <GoogleLogout
                        clientId={clientId}
                        render={renderProps => <Button onClick={renderProps.onClick} icon={google}>Sign out</Button>}
                        onLogoutSuccess={onSuccess}
                />
        )
}